import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ROUTES from 'shared/routes';
import TrackedButton from 'components/inputs/TrackedButton';
import TrackedCloseButton from 'components/inputs/TrackedCloseButton';
import PrivacyLink from '../inputs/PrivacyLink';

const points = [
    {
        index: 1,
        icon: <FavoriteIcon fontSize='small' />,
        header: 'Create a personalized collection',
        text: 'Build your collection just the way and how you want it.'
    },
    {
        index: 2,
        icon: <QueueMusicIcon fontSize='small' />,
        header: 'Experience the magic mix',
        text: 'Easily create your playlist based on your keywords, artists, dates…'
    },
    {
        index: 3,
        icon: <AccountCircleIcon fontSize='small' />,
        header: 'Improved listening experience',
        text: 'Enjoy a better experience listening to your favorite songs.'
    }
];

const MusicMixNotification = () => {
    return (
        <Container maxWidth='sm' className='notification'>
            <Box
                pl={3}
                pt={3}
                style={{
                    background:
                        'linear-gradient(rgba(0, 0, 0, 0.358) 85%, rgb(0, 0, 0)), url(/static/images/music-mix-banner.png) center center no-repeat'
                }}
            >
                <TrackedCloseButton name={'music-mix'} />

                <Box pl={5} pt={6} id='notification-title'>
                    <Typography variant='h6'>Introducing</Typography>
                    <Typography fontWeight={'bold'} variant='h4'>
                        Music Mix
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: '#000000'
                }}
            >
                <DialogContent>
                    <List>
                        {points.map(point => (
                            <ListItem key={point.index}>
                                <ListItemAvatar>
                                    <Avatar>{point.icon}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        textTransform: 'none'
                                    }}
                                    primaryTypographyProps={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        textTransform: 'none'
                                    }}
                                    secondaryTypographyProps={{
                                        fontSize: 10
                                    }}
                                    primary={point.header}
                                    secondary={point.text}
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <div className='notification-footer'>
                    <div>
                        <TrackedButton
                            event={{
                                event: 'notification:dialog:activity',
                                data: {
                                    name: 'music-mix',
                                    type: 'click'
                                }
                            }}
                            isClose
                            to={ROUTES.MUSIC_MIX}
                        >
                            Continue
                        </TrackedButton>
                    </div>
                    <PrivacyLink />
                </div>
            </Box>
        </Container>
    );
};

export default MusicMixNotification;
