import { getDatabase, onValue, ref } from 'firebase/database';
import app from 'overrides/firebase';

// Initialize database
const database = getDatabase(app);

export const getDatabaseValue = <T>(path: string) => {
    return new Promise<T>((resolve, reject) => {
        onValue(
            ref(database, path),
            snapshot => {
                if (snapshot.exists()) {
                    resolve(snapshot.val() as T);
                } else {
                    reject(null);
                }
            },
            {
                onlyOnce: true
            }
        );
    });
};

export default database;
