import type { ColorSchemeOption, ThemeType } from 'types';

export const toThemeType = (colorScheme: ColorSchemeOption): ThemeType => {
    if (colorScheme === 'system') {
        const preferDarkTheme =
            globalThis.matchMedia('(prefers-color-scheme: light)').matches !==
            true;

        return preferDarkTheme ? 'dark' : 'light';
    }

    return colorScheme;
};
