import JsCookie from 'js-cookie';
import { getCookieOptions } from 'helpers/cookie';

export type CookieKeys =
    | 'browserId'
    | 'pushNotificationAlertDismissed'
    | 'referralCode'
    | 'rememberMe'
    | 'shareId'
    | 'shouldAutoLogin'
    | 'username';

const Cookies = JsCookie.withAttributes(getCookieOptions());

const set = (
    name: CookieKeys,
    value: string,
    options?: JsCookie.CookieAttributes
) => {
    if (options) {
        options = getCookieOptions(options);
    }

    return Cookies.set(name, value, options);
};

const proxy = {
    get: (name?: CookieKeys) => {
        if (typeof name === 'string') {
            return Cookies.get(name);
        }

        return Cookies.get();
    },
    set,
    remove: (name: CookieKeys, options?: JsCookie.CookieAttributes) => {
        return Cookies.remove(name, options);
    }
};

export default proxy;
