import { v4 as uuid } from 'uuid';
import type { Track, TrackId } from 'types';
import { findTrackIndex, getTrackFile } from 'helpers/track';
import { onUserInteraction } from 'helpers/app';
import { Rights } from 'enums/rights';
import logger from 'utilities/logger';
import { getRecommendedTracks } from 'queries/tracks';
import { getRemoteConfigBooleanValue } from 'overrides/firebase.remote-config';
import { captureException, captureMessage } from 'overrides/sentry';
import { toSeconds } from 'helpers/to-seconds';
import { logAnalyticsCustomEvent } from 'overrides/firebase.analytics';
import { getRangesTotal } from 'helpers/time';
import audio, {
    getCurrentTime,
    resetPlaylistCount,
    seek,
    stop,
    setVolume,
    getTags
} from 'shared/web-player';
import { getDevicePreferenceByKey } from 'shared/preferences';
import type { PlayerOptions, PlayerInterface, ShuffleOption } from './type';
import mediaSession from './media-session';

type LoadOption = {
    currentItemId?: TrackId;
    currentItemIndex: number;
    isPlaying?: boolean;
    isSwitch?: boolean;
    position?: number;
};

type SkipDirection = 'next' | 'prev';

const soundless = onUserInteraction.then(function then() {
    const audio = new Audio('/static/audio/10-seconds-of-silence.mp3');

    audio.loop = true;

    return audio;
});

const getTrackSrc = (track: Track, isLoggedIn: boolean) => {
    const { file, artist, album, rights } = track as Pick<
        Track,
        'album' | 'artist' | 'file' | 'rights'
    >;

    // cache burst for guest users
    const filename = getTrackFile({
        file,
        artist,
        album,
        rights
    });

    const isCacheMusicEnabled = getRemoteConfigBooleanValue('CacheMusic');

    if (isLoggedIn && isCacheMusicEnabled) {
        return filename;
    }

    return `${filename}?`;
};

const getError = (error: MediaError | null) => {
    let message = '';

    switch (error?.code) {
        case MediaError.MEDIA_ERR_ABORTED:
            message = 'The user canceled the audio.';
            break;
        case MediaError.MEDIA_ERR_NETWORK:
            message = 'A network error occurred while fetching the audio.';
            break;
        case MediaError.MEDIA_ERR_DECODE:
            message = 'An error occurred while decoding the audio.';
            break;
        case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
            message =
                'The audio is missing or is in a format not supported by your browser.';
            break;
        default:
            message = 'An unknown error occurred.';
            break;
    }

    return {
        message,
        code: error?.code ?? -1
    };
};

class Player implements PlayerInterface<Track> {
    options: PlayerOptions;
    audio: HTMLAudioElement = audio;
    tracks: Track[] = [];
    currentIndex = 0;
    trackId = -1;
    isNewPlaylist = false;
    requestID?: number;
    skipStartIndex = -1;
    playing: Promise<void> | null = null;
    isRetry = false;

    constructor(options: PlayerOptions) {
        this.options = options;

        // this.audio.addEventListener('timeupdate', () => {
        //     logger.info(audio.currentTime);

        //     logger.info(`played: ${getRangesTotal(audio.played)}`);
        // });

        // this.audio.addEventListener('playing', () => {
        //     logger.info(`onplaying: ${this.audio.paused}`);
        // });

        // this.audio.addEventListener('play', () => {
        //     logger.info(`onplay: ${this.audio.paused}`);
        // });

        // this.audio.addEventListener('pause', () => {
        //     logger.info(`onpause: ${this.audio.paused}`);
        // });

        // this.audio.addEventListener('ended', () => {
        //     logger.info(`onended: ${this.audio.paused}`);
        // });

        // this.audio.addEventListener('canplaythrough', () => {
        //     logger.info(`oncanplaythrough: ${this.audio.paused}`);
        // });

        // this.audio.addEventListener('canplay', () => {
        //     logger.info(`oncanplay: ${this.audio.paused}`);
        // });

        // this.audio.addEventListener('emptied', () => {
        //     logger.info(`emptied: ${this.audio.paused}`);
        // });

        // this.audio.addEventListener('loadstart', () => {
        //     logger.info(`loadstart: ${this.audio.paused}`);
        // });

        // this.audio.addEventListener('loadeddata', () => {
        //     logger.info(`onloadeddata: ${this.audio.paused}`);
        // });

        // this.audio.addEventListener('loadedmetadata', () => {
        //     logger.info(`onloadedmetadata: ${this.audio.paused}`);
        // });

        // this.audio.addEventListener('error', () => {
        //     logger.info(`onerror: ${this.audio.paused}`);
        // });

        this.onerror = this.onerror.bind(this);
        this.onplay = this.onplay.bind(this);
        this.onplaying = this.onplaying.bind(this);
        this.onended = this.onended.bind(this);
        this.onload = this.onload.bind(this);
        this.onpause = this.onpause.bind(this);
        this.ontimeupdate = this.ontimeupdate.bind(this);
        this.onprogress = this.onprogress.bind(this);
        this.onloadstart = this.onloadstart.bind(this);

        this.audio.oncanplaythrough = this.onload;
        this.audio.onerror = this.onerror;
        this.audio.onplay = this.onplay;
        this.audio.onplaying = this.onplaying;
        this.audio.onpause = this.onpause;
        this.audio.onended = this.onended;
        this.audio.onprogress = this.onprogress;
        this.audio.onloadstart = this.onloadstart;

        if (getRemoteConfigBooleanValue('UseNativeTimeUpdate')) {
            this.audio.ontimeupdate = this.ontimeupdate;
        }

        // this.audio.crossOrigin = 'use-credentials';
        this.audio.preload = 'auto';
    }

    init({ track }: { track: Track }) {
        const { getState, onNext, onPrev, onPause, onPlay } = this.options;

        const {
            player: { params },
            user
        } = getState();
        const isLoggedIn = Boolean(user.id);

        const src = getTrackSrc(track, isLoggedIn);

        this.trackId = track.id;

        this.audio.src = src;

        this.audio.dataset.trackId = track.id as unknown as string;
        this.audio.dataset.paramId = params.id as string;
        this.audio.dataset.paramType = params.type;
        this.audio.dataset.generated = params?.generated
            ? params.generated.toString()
            : 'false';

        mediaSession.init({
            onNext,
            onPrev,
            onPause,
            onPlay
        });
    }

    getCurrentTrack() {
        return this.getItems()[this.currentIndex];
    }

    getIsLast() {
        const currentTrack = this.getCurrentTrack();
        const lastTrack = this.getItems()[this.getItems().length - 1];

        return lastTrack.id === currentTrack.id;
    }

    onloadstart() {
        this.options.onLoading();
    }

    onplay() {
        mediaSession.onPlay(this.getCurrentTrack());

        if (getRemoteConfigBooleanValue('PlaySilentSong')) {
            soundless.then(function then(audio) {
                if (audio.paused) {
                    audio.play().catch(logger.error);
                }
            });
        }

        const isLast = this.getIsLast();

        if (isLast) {
            const { repeat } = this.getState();

            if (repeat === 'none') {
                const isAutoplayEnabled =
                    getRemoteConfigBooleanValue('Autoplay');
                const autoplay = getDevicePreferenceByKey<boolean>(
                    'player.autoplay',
                    true
                );

                if (isAutoplayEnabled && autoplay) {
                    const data = {
                        trackIds: this.tracks.map(function map(track) {
                            return track?.id!;
                        })
                    };

                    getRecommendedTracks(data).then(items => {
                        this.options.load({
                            playlist: {
                                items: [...this.getItems(), ...items]
                            },
                            params: {
                                type: 'queue',
                                id: uuid()
                            },
                            trackId: this.getId()
                        });
                    });
                }
            }
        }
    }

    onplaying() {
        this.options.onPlaying();

        const track = this.getCurrentTrack();
        const label = `${track.title} - ${track.artist.stageName}`;

        logAnalyticsCustomEvent('playing', {
            // eslint-disable-next-line camelcase
            event_category: 'Audio',
            position: this.getPosition(),
            // eslint-disable-next-line camelcase
            event_label: label
        });

        // reset skip tracking
        this.skipStartIndex = -1;

        if (!getRemoteConfigBooleanValue('UseNativeTimeUpdate')) {
            this.ontimeupdate();
        }
    }

    onpause() {
        mediaSession.onPause();

        if (getRemoteConfigBooleanValue('PlaySilentSong')) {
            if (this.audio.paused) {
                soundless.then(function then(audio) {
                    return audio.pause();
                });
            }
        }

        this.options.onPaused();
    }

    onended() {
        const { repeat } = this.getState();
        const isLast = this.getIsLast();
        const isRepeatOne = repeat === 'one';
        const shouldStop = isLast && repeat === 'none';

        if (isRepeatOne) {
            this.playAudio();
        } else if (shouldStop) {
            this.pause();
            this.skip('next', false, true);
        } else {
            this.skip('next');
        }
    }

    onerror(event: Error | Event | string) {
        if (event instanceof Error) {
            if (event.name === 'AbortError') {
                return;
            }
        }

        const shouldRetry =
            [
                MediaError.MEDIA_ERR_NETWORK,
                MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED
            ].includes((this.audio.error?.code as unknown as 2 | 4) ?? -1) &&
            !this.isRetry;

        if (!shouldRetry) {
            this.isRetry = false;

            const error = getError(this.audio.error);
            const track = this.getCurrentTrack();

            const { snackbarOpen, onError } = this.options;

            snackbarOpen(`Yawa, Skipping ${track.title}`);

            setTimeout(() => {
                this.next(true);
            }, 500);

            onError(event);

            const tags = getTags(track);

            if (event instanceof Error) {
                captureException(event, {
                    tags
                });
            } else {
                captureMessage(
                    `Player.onerror:${error.code}|${error.message}`,
                    {
                        tags
                    }
                );
            }
        } else {
            this.isRetry = true;
            this.audio.load();
        }
    }

    onload() {
        this.options.onBufferFull(this.trackId);
    }

    onstop() {
        this.options.onStop();

        if (getRemoteConfigBooleanValue('PlaySilentSong')) {
            soundless.then(audio => audio.pause());
        }
    }

    getPosition() {
        return getCurrentTime();
    }

    getDuration() {
        if (Number.isNaN(this.audio.duration)) {
            const track = this.getCurrentTrack();
            const duration = toSeconds(track.duration);

            return duration;
        }

        return this.audio.duration ?? 0;
    }

    load(
        playlist: Track[],
        {
            currentItemId,
            currentItemIndex,
            isPlaying,
            isSwitch,
            position
        }: LoadOption
    ) {
        if (playlist.length === 0) {
            return;
        }

        resetPlaylistCount();

        this.tracks = playlist;
        this.currentIndex = currentItemIndex;
        this.isNewPlaylist = true;
        const isDefaultIndex = currentItemIndex === 0;

        if (!isDefaultIndex && isSwitch) {
            this.play({
                trackId: currentItemId,
                trackIndex: currentItemIndex
            });
        }
    }

    repeat() {}

    getState() {
        const { player } = this.options.getState();

        return player;
    }

    play({
        trackId,
        trackIndex
    }: { trackIndex?: number; trackId?: number } = {}) {
        const tracks = this.getItems();

        if (!Array.isArray(tracks) || tracks.length === 0) {
            logger.error(
                `tracks is either not an Array or is empty, type: ${typeof tracks}`
            );

            return;
        }

        let index = 0;
        const isPlayButton = trackId === undefined && trackIndex === undefined;
        const isPlayButtonAndIsNotNewPlaylist =
            isPlayButton && !this.isNewPlaylist;

        if (trackId !== undefined) {
            const foundIndex = findTrackIndex(tracks, trackId);

            if (foundIndex !== -1) {
                index = foundIndex;
            }
        } else if (trackIndex !== undefined) {
            index = trackIndex;
        } else {
            const { currentItemIndex } = this.getState();

            index = currentItemIndex;
        }

        const track = tracks[index];

        if (!track) {
            logger.error('Player: track is not defined: %O - %s', track, index);

            return;
        }

        const isSameTrack = this.trackId === track.id;

        const canStream = track.rights !== Rights.READ;

        this.currentIndex = index;

        if (canStream) {
            if (!(isSameTrack || isPlayButtonAndIsNotNewPlaylist)) {
                this.options.onPlaylistItem({
                    trackId: track.id
                });
                this.init({ track });
            } else if (isSameTrack && this.audio.error) {
                this.audio.load();
            }

            const isPlaying = !this.audio.paused;

            if (!isPlaying) {
                this.playAudio();
            }
        } else {
            const { snackbarOpen } = this.options;

            snackbarOpen(`${track.title} cannot be streamed at the moment`);

            this.next(true);
        }

        this.isNewPlaylist = false;
    }

    playAudio() {
        this.playing = this.audio.play();

        this.playing
            .then(() => {
                mediaSession.update(this.getCurrentTrack());
            })
            .catch(e => {
                if (e.code === DOMException.ABORT_ERR) {
                    return;
                }

                const track = this.getCurrentTrack();
                const tags = getTags(track);

                captureException(e, {
                    tags
                });
            })
            .finally(() => {
                this.playing = null;
            });
    }

    pause() {
        if (this.playing) {
            this.playing.then(() => {
                this.audio.pause();
            });
        } else if (!this.audio.paused) {
            this.audio.pause();
        }
    }

    skip(direction: SkipDirection, isError = false, stop = false) {
        if (isError) {
            if (this.skipStartIndex === -1) {
                this.skipStartIndex = this.currentIndex;
            } else if (this.skipStartIndex === this.currentIndex) {
                this.skipStartIndex = -1;

                this.stop();

                return;
            }
        }

        let index = 0;
        const count = this.getItems().length;

        if (direction === 'prev') {
            index = this.currentIndex - 1;

            if (index < 0) {
                index = count - 1;
            }
        } else {
            index = this.currentIndex + 1;

            if (index >= count) {
                index = 0;
            }
        }

        this.currentIndex = index;

        if (!stop) {
            this.skipTo(index);
        } else {
            const track = this.getCurrentTrack();

            this.options.onPlaylistItem({
                trackId: track.id
            });

            this.isNewPlaylist = true;
        }
    }

    skipTo(trackIndex: number) {
        this.play({
            trackIndex
        });
    }

    next(isError?: boolean) {
        this.skip('next', isError);
    }

    prev() {
        this.skip('prev');
    }

    shuffle({ tracks /* , trackIndex*/ }: ShuffleOption<Track>) {
        if (tracks) {
            // find current track, and carry it over.
            const track = this.getItems()[this.currentIndex];

            this.currentIndex = findTrackIndex(tracks, track.id);

            this.tracks = tracks;
        }
    }

    seek(position: number) {
        if (!getRemoteConfigBooleanValue('UseNativeTimeUpdate')) {
            this.requestID && cancelAnimationFrame(this.requestID);
        }

        seek(position);
    }

    stop() {
        if (!getRemoteConfigBooleanValue('UseNativeTimeUpdate')) {
            this.requestID && cancelAnimationFrame(this.requestID);
        }

        stop();

        this.options.onStop();
    }

    fromVolume(value: number) {
        return value * 100;
    }

    volume(value: number) {
        setVolume(value);
    }

    ontimeupdate() {
        const state = this.getState();

        const position = Math.floor(this.getPosition());
        const duration = Math.floor(this.getDuration());

        mediaSession.onPosition({
            position,
            duration
        });

        if (!getRemoteConfigBooleanValue('UseNativeTimeUpdate')) {
            if (
                state.status === 'playing' ||
                (state.status === 'loading' && position === 0)
            ) {
                setTimeout(() => {
                    this.requestID = requestAnimationFrame(this.ontimeupdate);
                }, 250);
            }
        }
    }

    onprogress() {
        const buffered = getRangesTotal(this.audio.buffered);
        const duration = this.getDuration();
        const bufferPercent = (buffered / duration) * 100;

        this.options.onBufferChange({
            bufferPercent
        });
    }

    getItems() {
        return this.tracks;
    }

    getId() {
        return this.trackId ?? -1;
    }

    add(tracks: Track[]) {
        this.tracks = [...this.tracks, ...tracks];
    }

    remove(trackId: number) {
        this.tracks = [
            ...this.tracks.filter(track => {
                return track.id !== trackId;
            })
        ];
    }
}
export default Player;
