/* eslint-disable no-else-return */
import { findTrackIndex } from 'helpers/track';
import type {
    PlayerRepeat,
    PlayerPlaylist,
    Track,
    TrackId,
    PlaylistId
} from 'types';
import type { Store } from 'store';
import logger from 'utilities/logger';
import { getCurrentTime } from 'shared/web-player';
import type { PlayerInterface } from './type';
// const TIMER_STEP = 1000;
const SKIP_RESET = 3;

type LoadOption = {
    currentItemId?: TrackId;
    currentItemIndex?: number;
    id: string;
    isPlaying?: boolean;
    tracks: Track[];
    position?: number;
    skipOverride: boolean;
    autoPlay?: boolean;
};

type Player = PlayerInterface<chrome.cast.media.QueueItem | Track>;
class PlayerProxy {
    // timer?: null | NodeJS.Timeout;
    player?: Player;
    store?: Store;
    isReady: boolean;

    constructor() {
        this.isReady = false;
    }

    setStore(store: Store) {
        this.store = store;
    }

    getPlayerState() {
        const state = this.getState();

        if (state) {
            return {
                ...state.player,
                position: getCurrentTime()
            };
        }

        return {
            bufferPercent: 0,
            counted: false,
            currentItemIndex: 0,
            currentItemId: 0 as TrackId,
            isReady: false,
            position: getCurrentTime(),
            playlist: {
                id: '' as PlaylistId,
                items: []
            } as PlayerPlaylist,
            repeat: 'none' as PlayerRepeat,
            shuffle: false,
            status: 'idle',
            totalPlay: 0
        };
    }

    getPlaylistItems() {
        return this.player?.getItems() ?? [];
    }

    getIsReady() {
        return this.isReady;
    }

    setPlayer(player: Player, skipLoad = false) {
        if (player !== undefined) {
            const { playlist, currentItemIndex, position, repeat } =
                this.getPlayerState();

            if (this.player) {
                this.stop();
            }

            this.player = player;

            if (!skipLoad && playlist.items.length !== 0) {
                player.load(playlist.items, {
                    currentItemIndex,
                    position,
                    isSwitch: true,
                    repeat
                });
            }

            this.isReady = true;
        }
    }

    shufflePlaylist(playlist: any[]) {
        playlist = [...playlist];
        let currentIndex = playlist.length,
            tempValue,
            randomIndex;

        // While there remains elements to shuffle...
        while (currentIndex !== 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * playlist.length);
            currentIndex -= 1;

            // And swap it with the current element...
            tempValue = playlist[currentIndex];
            playlist[currentIndex] = playlist[randomIndex];
            playlist[randomIndex] = tempValue;
        }

        return playlist;
    }

    load({
        tracks,
        skipOverride = false,
        currentItemIndex = 0,
        currentItemId
    }: LoadOption) {
        if (!this.player || tracks.length === 0) {
            return;
        }

        const playerState = this.getPlayerState();

        // this.state.id = id;
        if (!skipOverride) {
            const isShuffled = playerState.shuffle;

            if (isShuffled) {
                tracks = this.shufflePlaylist(tracks);
            }
        }

        this.player.load(tracks, {
            currentItemIndex,
            currentItemId,
            isPlaying: playerState.status === 'playing',
            repeat: playerState.repeat
        });
    }

    play(trackId?: number) {
        if (this.player) {
            if (trackId) {
                const playerState = this.getPlayerState();
                const isPlaying = playerState.status === 'playing';
                // const isPaused = playerState.state === 'paused';
                const isSameTrack = this.player.getId() === trackId;

                if (isSameTrack && isPlaying) {
                    this.player.pause();
                } else {
                    this.player.play({ trackId });
                }
            } else {
                this.player.play();
            }

            this.startProgressTimer();
        } else {
            logger.warn(`player is not set ${this.player}`);
        }
    }

    stop() {
        if (this.player) {
            this.player.stop();
            this.stopProgressTimer();
        }
    }

    pause() {
        if (this.player) {
            this.player.pause();
        }
    }

    next() {
        if (this.player) {
            this.player.next();
        }
    }

    prev() {
        if (this.player) {
            const position = this.getPosition();

            if (SKIP_RESET < position) {
                this.seek(0);
            } else {
                this.player.prev();
            }
        }
    }

    repeat(value: PlayerRepeat) {
        if (this.player) {
            this.player.repeat(value);
        }
    }

    getPosition() {
        if (this.player) {
            return this.player.getPosition();
        }

        return 0;
    }

    getState() {
        if (this.store) {
            return this.store.getState();
        } else {
            logger.warn('store is not set');
        }
    }

    getCurrentItem() {
        const playerState = this.getPlayerState();

        if (playerState) {
            const { playlist, currentItemIndex } = playerState;
            const track = playlist.items[currentItemIndex];

            if (!track) {
                logger.warn(
                    `cannot find track @ ${currentItemIndex} for ${playlist}`
                );
            }

            return track;
        }
    }

    getPlaylistItemsLength() {
        return this.getPlaylistItems().length;
    }

    getNextIndex() {
        const length = this.getPlaylistItemsLength();
        const playerState = this.getPlayerState();

        if (playerState) {
            const nextIndex = playerState.currentItemIndex + 1;

            return length > nextIndex ? nextIndex : 0;
        }

        return 0;
    }

    getPrevIndex() {
        const length = this.getPlaylistItemsLength();
        const playerState = this.getPlayerState();

        if (playerState) {
            const prevIndex = playerState.currentItemIndex - 1;

            return prevIndex >= 0 ? prevIndex : length - 1;
        }

        return length - 1;
    }

    shuffle() {
        if (!this.player) {
            return;
        }

        const tracks = this.getPlaylistItems();
        const playerState = this.getPlayerState();
        const isShuffled = playerState?.shuffle;

        const nextTracks = isShuffled ? this.shufflePlaylist(tracks) : tracks;

        const currentItem = this.getCurrentItem();

        if (currentItem && playerState) {
            const trackIndex = findTrackIndex(nextTracks, currentItem.id);

            this.player.shuffle({
                trackIndex,
                tracks: nextTracks
            });
        }
    }

    seek(position: number) {
        if (this.player) {
            logger.info(`@proxy.seek: ${position}`);
            this.player.seek(position);
        }
    }

    startProgressTimer() {
        // this.stopProgressTimer();
        // // Start progress timer
        // this.timer = setInterval(
        //     this.onIncrementMediaTime.bind(this),
        //     TIMER_STEP
        // );
    }

    stopProgressTimer() {
        // if (this.timer) {
        //     clearInterval(this.timer);
        //     this.timer = null;
        // }
    }

    onIncrementMediaTime() {
        if (this.player) {
            // First sync with the current player's time
            const position = this.player.getPosition();
            const duration = this.player.getDuration();

            // this.playerHandler.updateDurationDisplay();

            if (duration == null || position < duration) {
                // this.playerHandler.updateCurrentTimeDisplay();
                // this.updateProgressBarByTimer();
            } else if (duration > 0) {
                this.endPlayback();
            }
        }
    }

    endPlayback() {}

    volume(value: number) {
        if (this.player) {
            this.player.volume(value);
        }
    }

    add(tracks: (chrome.cast.media.QueueItem | Track)[]) {
        if (this.player) {
            this.player.add(tracks);
        }
    }

    remove(trackId: number | string) {
        if (this.player) {
            this.player.remove(trackId);
        }
    }
}

const proxy = new PlayerProxy();

export default proxy;
