import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';
import type { Location } from 'react-router-dom';
import ErrorMessage from 'components/ErrorMessage';
import RefreshButton from 'components/inputs/RefreshButton';
import { logAnalyticsEvent } from 'overrides/firebase.analytics';
import { captureException } from 'overrides/sentry';

type State = {
    error: Error | null;
};

type Props = {
    location: Location;
    children: ReactNode;
};

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            error: null
        };
    }

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    componentDidUpdate({ location: prevLocation }: Props) {
        const { location } = this.props;
        const { error } = this.state;
        const { pathname } = location;
        const routeChanged = prevLocation.pathname !== pathname;

        if (routeChanged) {
            if (error) {
                this.setState({
                    error: null
                });
            }
        }
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        logAnalyticsEvent('exception', {
            description: error.toString(),
            fatal: 'true'
        });

        captureException(error, {
            extra: {
                info
            }
        });
    }

    render() {
        const { error } = this.state;
        const { children } = this.props;

        if (error) {
            const message =
                error.message ?? 'Something don happen, try again. No vex';

            return (
                <ErrorMessage message={message}>
                    <p>
                        <RefreshButton variant='outlined' />
                    </p>
                </ErrorMessage>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
