import type { QueryClient } from '@tanstack/react-query';
import { lazy } from 'react';
import { homeQuery } from 'queries/pages';
export default lazy(() => import(/* webpackChunkName: "home" */ './Home'));

export const homeLoader = (queryClient: QueryClient) => {
    return async () => {
        return queryClient.ensureQueryData(homeQuery);
    };
};
