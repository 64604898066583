import type { Middleware } from 'redux';
import Cookies from 'overrides/js-cookie';
import type { RouteValues } from 'shared/routes';
import ROUTES from 'shared/routes';
import type Actions from 'actions';
import { snackbarOpen, fetchToken, fetchRefreshToken } from 'actions';
import {
    FETCH_LOGOUT_ERROR,
    FETCH_LOGOUT_SUCCESS,
    FETCH_TOKEN_ERROR,
    FETCH_TOKEN_SUCCESS,
    FETCH_USERS_SUCCESS,
    ON_PAGE_READY
} from 'actions/types';
import { getUriRoute } from 'helpers/uri';
import { getUserPicture } from 'helpers/image';
import { isCypress } from 'helpers/env';
import type { AppDispatch, RootState } from 'store';
import logger, { axiosLogger } from 'utilities/logger';
import { getRemoteConfigBooleanValue } from 'overrides/firebase.remote-config';
import type { FetchResponse, FetchResponseError } from 'types/fetch';
import { updateOrCreateSession } from 'queries/global';
import queryClient from 'shared/query-client';

// const user = firebase.auth().currentUser;
// const unsubscribe = firebase.auth().onAuthStateChanged(user => {
//     if (user) {
//         unsubscribe();
//         logger.info('User signed in: ', user);

//         // localStorage.removeItem(firebaseAuthKey);

//         // here you could authenticate with you web server to get the
//         // application specific token so that you do not have to
//         // authenticate with firebase every time a user logs in
//         // localStorage.setItem(appTokenKey, user.uid);

//         // store the token
//         // this.props.history.push('/app/home');

//         // const user = result.user;
//         const data = {
//             // ...result.additionalUserInfo.profile,
//             // ...result.credential,
//             refreshToken: user.refreshToken
//         };
//         data.accountId = data.id;
//         delete data.id;
//         user.getIdToken().then(idToken => {
//             data.idToken = idToken;
//             // this.props.userLoginOpenId(data);
//         });
//     }
// });

// firebase.auth().onAuthStateChanged(user => {
//     if (user) {
//         logger.info('User signed in: ', JSON.stringify(user));

//         // localStorage.removeItem(firebaseAuthKey);

//         // here you could authenticate with you web server to get the
//         // application specific token so that you do not have to
//         // authenticate with firebase every time a user logs in
//         // localStorage.setItem(appTokenKey, user.uid);

//         // store the token
//         // this.props.history.push('/app/home');
//     }
// });
const credOptions: CredentialRequestOptions = {
    password: true,
    federated: {
        providers: [
            // 'https://accounts.google.com',
            // 'https://www.facebook.com'
        ]
    },
    mediation: 'optional'
};

const supportsCredentials = Boolean(
    'credentials' in navigator &&
        (window as any).PasswordCredential &&
        (window as any).FederatedCredential &&
        (window as any).PublicKeyCredential
);

let isAutoLogin = false;

const loginPromptIgnoreList = [
    ROUTES.LOGIN,
    ROUTES.REGISTER,
    ROUTES.FORGOT_PASSWORD,
    ROUTES.RESET_PASSWORD,
    ROUTES.ACTIVATION,
    ROUTES.VERIFY_ACCOUNT
] as RouteValues[];

const auth: Middleware<{}, RootState, AppDispatch> =
    ({ dispatch, getState }) =>
    next =>
        async function auth(action: Actions) {
            const location = window.location;
            const prevState = getState();

            const result = next(action);

            switch (action.type) {
                case ON_PAGE_READY: {
                    const shouldAutoLogin =
                        Cookies.get('shouldAutoLogin') === 'true';

                    const route = getUriRoute(location.pathname);
                    const isInLoginPromptIgnoreList =
                        loginPromptIgnoreList.includes(route);

                    if (!isInLoginPromptIgnoreList) {
                        if (shouldAutoLogin) {
                            isAutoLogin = true;
                            dispatch(
                                fetchToken({
                                    method: 'post',
                                    loginType: 'Auto'
                                })
                            );
                        } else if (supportsCredentials) {
                            navigator.credentials
                                .get(credOptions)
                                .then(credential => {
                                    if (credential) {
                                        switch (credential.type) {
                                            case 'password':
                                                if (credential.password) {
                                                    dispatch(
                                                        fetchToken({
                                                            method: 'post',
                                                            options: {
                                                                data: {
                                                                    username:
                                                                        credential.id,
                                                                    password:
                                                                        credential.password
                                                                }
                                                            },
                                                            loginType:
                                                                'SmartLock'
                                                        })
                                                    );
                                                }

                                                break;
                                            case 'federated':
                                                // TODO [2025-10-30]: implement for Google
                                                // dispatch(userRegisterOpenId());
                                                break;
                                            default:
                                        }
                                    }
                                })
                                .catch(e => {
                                    logger.warn(e);
                                });
                        }
                    }

                    break;
                }
                case FETCH_LOGOUT_SUCCESS: {
                    Cookies.remove('username');
                    Cookies.remove('rememberMe');
                    Cookies.remove('shouldAutoLogin');
                    dispatch(snackbarOpen('You have successfully logged out'));

                    if (supportsCredentials && !isCypress()) {
                        navigator.credentials
                            ?.preventSilentAccess()
                            .catch(logger.error);
                    }

                    break;
                }
                case FETCH_LOGOUT_ERROR: {
                    const { error } = action.payload;

                    dispatch(
                        snackbarOpen((error as FetchResponseError).message)
                    );
                    break;
                }
                case FETCH_USERS_SUCCESS: {
                    if (prevState.user.id) {
                        break;
                    }

                    const {
                        data,
                        meta: {
                            // @ts-ignore
                            originalAction: {
                                payload: { options, method }
                            }
                        }
                    } = action.payload;

                    if (method === 'get') {
                        if (options?.data && supportsCredentials) {
                            const credOptions = {
                                iconURL: getUserPicture(data) || '',
                                id: data.email,
                                name: `${data.firstName} ${data.lastName}`,
                                password: options.data.password,
                                provider: options.data.provider
                                    ? 'https://accounts.google.com'
                                    : ''
                            };

                            // TODO [2025-10-30]: support for non-password options
                            if (credOptions.password) {
                                try {
                                    const isFed = Boolean(credOptions.provider);
                                    let cred;

                                    if (isFed) {
                                        cred = new FederatedCredential(
                                            credOptions
                                        );
                                    } else {
                                        cred = new PasswordCredential(
                                            credOptions
                                        );
                                    }

                                    await navigator.credentials.store(cred);
                                } catch (e) {
                                    logger.error(e);
                                }
                            }
                        }

                        const isKeepUserLoggedInEnabled =
                            getRemoteConfigBooleanValue('KeepUserLoggedIn');

                        if (!(isKeepUserLoggedInEnabled && isAutoLogin)) {
                            dispatch(
                                snackbarOpen(`Signed in as ${data.email}`)
                            );
                        }

                        scheduler.postTask(
                            () => {
                                updateOrCreateSession().catch(e => {
                                    axiosLogger.error(e);
                                });
                            },
                            {
                                priority: 'background'
                            }
                        );
                    }

                    break;
                }
                case FETCH_TOKEN_SUCCESS: {
                    const { refreshToken, expiresIn } =
                        action.payload as FetchResponse;
                    const oneMinute = 60;
                    const sevenDays = 7 * 24 * 60 * 60;

                    const accessTokenExpires =
                        ((expiresIn ?? sevenDays) - oneMinute) * 1000; // you can configure as you want but here it is 1 min before token will get expired

                    setTimeout(() => {
                        dispatch(
                            fetchRefreshToken({
                                options: {
                                    data: {
                                        refreshToken
                                    }
                                }
                            })
                        );
                    }, accessTokenExpires);

                    queryClient.invalidateQueries({
                        predicate: query => {
                            return (query.queryKey[0] as string)?.startsWith(
                                'user.'
                            );
                        }
                    });
                    break;
                }
                case FETCH_TOKEN_ERROR: {
                    if (isAutoLogin) {
                        dispatch(snackbarOpen('Login failed'));

                        isAutoLogin = false;
                    }

                    break;
                }
                default:
            }

            return result;
        };

export default auth;
