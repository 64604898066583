import {
    ensureInitialized,
    fetchAndActivate,
    getAll,
    getRemoteConfig,
    getValue
} from 'firebase/remote-config';
import app from 'overrides/firebase';
import config from 'config';
import remoteConfigDefaults from 'config/remote-config-defaults.json';
import logger from 'utilities/logger';
import type { FilterByType } from 'types';

export type RemoteConfigValues = typeof remoteConfigDefaults;
export type RemoteConfigBooleanValue = FilterByType<
    RemoteConfigValues,
    boolean
>;

export type RemoteConfigStringValue = FilterByType<RemoteConfigValues, string>;

export type RemoteConfigNumberValue = FilterByType<RemoteConfigValues, number>;

export type RemoteConfigKeys = keyof RemoteConfigValues;

// Initialize remote config
const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = remoteConfigDefaults;

if (config.FIREBASE.minimumFetchIntervalMillis) {
    remoteConfig.settings.minimumFetchIntervalMillis =
        config.FIREBASE.minimumFetchIntervalMillis;

    if (config.ENV === 'local') {
        remoteConfig.settings.minimumFetchIntervalMillis = 0;
    }
}

ensureInitialized(remoteConfig).catch(logger.error);

export const getRemoteConfigBooleanValue = (key: RemoteConfigBooleanValue) => {
    return getValue(remoteConfig, key).asBoolean();
};

export const getRemoteConfigNumberValue = (key: RemoteConfigNumberValue) => {
    return getValue(remoteConfig, key).asNumber();
};

export const getRemoteConfigStringValue = (key: RemoteConfigStringValue) => {
    return getValue(remoteConfig, key).asString();
};

export const fetchAndActivateRemoteConfig = () => {
    return fetchAndActivate(remoteConfig)
        .then((activated: boolean) => {
            logger.debug(JSON.stringify(getAll(remoteConfig)));

            if (!activated) {
                logger.debug('Remote config not activated');
            } else {
                logger.debug('Remote config activated');
            }

            return activated;
        })
        .catch(logger.error);
};

export const isRemoteConfigReady = fetchAndActivateRemoteConfig();

export default app;
