import { compile } from 'path-to-regexp';
import type {
    UseMutationOptions,
    UseQueryOptions
} from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import type { AxiosResponseWithData } from 'overrides/axios';
import axios from 'overrides/axios';
import type {
    Artist,
    Playlist,
    SalesSummary,
    Track,
    Ticket,
    TicketType,
    Currency,
    Show,
    ShowParams,
    Order,
    User,
    Bank,
    Promoter,
    ShowFeeHandling,
    Dj,
    StripeAccount,
    TicketTypesById,
    CountryId,
    StateId,
    ShowId,
    DjBooking,
    DjBookingParams,
    PaginationMeta,
    PaginationParams,
    DjBookingId,
    AccountPreferences,
    PlaylistId,
    TicketTypeId,
    UserId,
    ReferralCode,
    Recents
} from 'types';
import type { PaymentOptions } from 'enums/payment-options';
import type { FetchResponseError } from 'types/fetch';
import { snackbarOpen } from 'actions';
import type { AppDispatch } from 'store';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import queryClient from 'shared/query-client';
import { playlistQueryKeys } from 'queries/playlists';
import URIS from './uris.json';

const getPlaylistsUrl = compile(`${URIS.users.playlists.index}`);

const getActivationUrl = compile(`${URIS.users.activation}`);
const getUserShowsUrl = compile<Partial<ShowParams>>(
    `${URIS.users.shows.index}`
);

const getUserDjBookingUrl = compile<Partial<DjBookingParams>>(
    URIS.users.bookings.index
);
const getDjBookingOrderUrl = compile<DjBookingParams>(
    `${URIS.orders.bookings}`
);

const getShowTicketTypeUrl = compile<
    ShowParams & {
        ticketTypeId: TicketTypeId;
    }
>(`${URIS.users.shows.ticketType}`);
const getShowTicketsUrl = compile<ShowParams>(`${URIS.users.shows.tickets}`);
const getShowPayoutUrl = compile<ShowParams>(`${URIS.users.shows.payout}`);
const getUserShowPublishUrl = compile<ShowParams>(
    `${URIS.users.shows.publish}`
);
const getShowBookUrl = compile<ShowParams>(`${URIS.users.shows.book}`);
const getShowOrderUrl = compile<ShowParams>(`${URIS.orders.shows}`);
const getUserUpdateUrl = compile<{ userId: UserId }>(
    `${URIS.users.restricted.users}`
);
const getUserVerificationUrl = compile<{ userId: UserId }>(
    `${URIS.verifications}`
);
const getUserPlaylistLikesUrl = compile<{ playlistId: PlaylistId }>(
    `${URIS.users.likes.playlists}`
);
const getPlaylistTracksUrl = compile<{
    playlistId: PlaylistId;
    trackIndex?: number;
}>(`${URIS.users.playlists.tracks}`);

export const usersQueryKeys = {
    USER_PLAYLIST_LIKES: 'user.playlist.likes',
    USER_PLAYLISTS: 'user.playlists',
    USER_RECENTLY_PLAYED: 'user.recently-played',
    USER_SHOW_PAYOUT: 'user.show.payout',
    USER_SHOW_TICKETS: 'user.show.tickets',
    USER_SHOWS: 'user.shows',
    USER_DJ_BOOKING: 'user.dj-booking',
    USER_DJ_BOOKINGS: 'user.dj-bookings',
    USER_PREFERENCES: 'user.preferences',
    USER: 'user',
    USERS: 'users',
    USER_ACTIVATION: 'user.activation',
    USER_BOOKING: 'user.booking',
    USER_EVENTS: 'user.events'
};

export type UserPlaylistOption = {
    playlistId?: string;
};
export const useUserPlaylistsQuery = <T = Playlist[]>(
    { playlistId }: UserPlaylistOption = {},
    options?: UseQueryOptions<T, FetchResponseError>
) => {
    const isLoggedIn = useIsLoggedIn();
    const url = getPlaylistsUrl({
        playlistId
    });
    const params: Record<string, string> = {};
    const fields = (
        playlistId
            ? ['tracks.album.price', 'tracks.artist', 'tracks.artists']
            : []
    ).join();

    if (fields) {
        params.fields = fields;
    }

    return useQuery<T, FetchResponseError>({
        ...options,
        queryKey: [usersQueryKeys.USER_PLAYLISTS, { playlistId, fields }],
        queryFn: async () => {
            const response = await axios.get<T>(url, {
                params
            });

            return response.data;
        },
        enabled: isLoggedIn
    });
};

type CreatePlaylistOption = Required<
    Pick<Playlist, 'access' | 'description' | 'name' | 'tracks'>
>;
export const useUserPlaylistCreateMutation = (
    options?: UseMutationOptions<{}, FetchResponseError, CreatePlaylistOption>
) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: async ({ tracks, ...data }: CreatePlaylistOption) => {
            const response = await axios.post<
                Playlist,
                AxiosResponseWithData<Playlist>
            >(getPlaylistsUrl(), {
                ...data,
                tracks: tracks.map(track => track.id)
            });

            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries([usersQueryKeys.USER_PLAYLISTS]);
        }
    });
};

export const useUserPlaylistUpdateMutation = (
    options?: UseMutationOptions<
        Playlist,
        FetchResponseError,
        Partial<Playlist>
    >
) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch<AppDispatch>();

    return useMutation({
        ...options,
        mutationFn: async ({
            id: playlistId,
            tracks = [],
            ...data
        }: Partial<Playlist>) => {
            const response = await axios.patch<Playlist>(
                getPlaylistsUrl({
                    playlistId
                }),
                {
                    ...data,
                    tracks: tracks.map(track => track.id)
                }
            );

            return response.data;
        },
        onSuccess: (data: Playlist, variables: Partial<Playlist>) => {
            const message = `${variables.name} updated successfully`;

            queryClient.invalidateQueries([
                usersQueryKeys.USER_PLAYLISTS,
                { playlistId: variables.id }
            ]);

            dispatch(snackbarOpen(message));
        },
        onError: (error: FetchResponseError, variables: Partial<Playlist>) => {
            const message =
                error.message ?? `error while updating ${variables.name}`;

            dispatch(snackbarOpen(message));
        }
    });
};

export const useUserPlaylistDeleteMutation = (
    options?: UseMutationOptions<{}, FetchResponseError, Partial<Playlist>>
) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch<AppDispatch>();

    return useMutation({
        ...options,
        mutationFn: async ({ id: playlistId }: Playlist) => {
            const response = await axios.delete<{}, AxiosResponseWithData<{}>>(
                getPlaylistsUrl({
                    playlistId
                })
            );

            return response.data;
        },
        onSuccess: (data, variables) => {
            const message = `${variables.name} deleted successfully`;

            queryClient.invalidateQueries([usersQueryKeys.USER_PLAYLISTS]);

            dispatch(snackbarOpen(message));
        },
        onError: (error, variables) => {
            const message = `error while deleting ${variables.name}`;

            dispatch(snackbarOpen(message));
        }
    });
};

export const useUserPlaylistItemDeleteMutation = (
    playlistId: PlaylistId,
    options?: UseMutationOptions<
        {},
        FetchResponseError,
        {
            trackIndex: number;
        }
    >
) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch<AppDispatch>();

    return useMutation({
        ...options,
        mutationFn: async ({ trackIndex }: { trackIndex: number }) => {
            const response = await axios.delete<{}, AxiosResponseWithData<{}>>(
                getPlaylistTracksUrl({
                    playlistId,
                    trackIndex
                })
            );

            return response.data;
        },
        onSuccess: () => {
            const message = 'Track removed successfully';

            queryClient.invalidateQueries([
                usersQueryKeys.USER_PLAYLISTS,
                { playlistId }
            ]);

            dispatch(snackbarOpen(message));
        },
        onError: (error: FetchResponseError) => {
            const message = error.message ?? 'error while removing track';

            dispatch(snackbarOpen(message));
        }
    });
};

type UserForgotPasswordRequest = {
    email: string;
};

const mutateUserForgotPassword = async (data: UserForgotPasswordRequest) => {
    const response = await axios.post<{}>(URIS.users.password.forgot, data);

    return response.data;
};

export const useUserForgotPasswordMutation = (
    options?: UseMutationOptions<
        {},
        FetchResponseError,
        UserForgotPasswordRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: mutateUserForgotPassword
    });
};

type UserResetPasswordRequest = {
    id: string;
    key: string;
    password: string;
    passwordConfirm: string;
    email: string;
};
const mutateUserPassword = async (data: UserResetPasswordRequest) => {
    const response = await axios.patch<{}>(URIS.users.password.index, data);

    return response.data;
};

export const useUserPasswordMutation = (
    options?: UseMutationOptions<
        {},
        FetchResponseError,
        UserResetPasswordRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: mutateUserPassword
    });
};

type UserPostRequest = {
    confirmTerms: boolean;
    email: string;
    firstName: string;
    gender: string;
    lastName: string;
    password: string;
    passwordConfirm: string;
    phoneNumber: string;
    providerId?: string;
    refreshToken?: string;
    signInMethod?: string;
    type: string;
};

const createUser = async (data: UserPostRequest) => {
    const response = await axios.post<{}>(URIS.users.index, data, {
        withCredentials: true
    });

    return response.data;
};

export const useUserCreateMutation = (
    options?: UseMutationOptions<
        {},
        FetchResponseError,
        UserForgotPasswordRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: createUser
    });
};

type UserActivationRequest = {
    email: string;
    key: string;
};
export const useUserActivationMutation = (
    { email, key }: UserActivationRequest,
    options?: UseMutationOptions<{}, FetchResponseError>
) => {
    return useMutation({
        ...options,
        mutationFn: async () => {
            const response = await axios.patch<{}>(
                getActivationUrl({
                    email,
                    key
                })
            );

            return response.data;
        }
    });
};

const getUserShows = async () => {
    const response = await axios.get<Show[]>(getUserShowsUrl(), {
        params: {
            fields: ['ticket_types'].join()
        }
    });

    return response.data;
};

export const useUserShowsQuery = () => {
    return useQuery<Show[], FetchResponseError>(
        [usersQueryKeys.USER_SHOWS],
        getUserShows
    );
};

type UserShowTicketTypeRequest = {
    data: Partial<TicketType>;
    params: {
        showId: ShowId;
        ticketTypeId: TicketTypeId;
    };
};
export const useUserShowTicketTypeMutation = (
    options?: UseMutationOptions<
        TicketType,
        FetchResponseError,
        UserShowTicketTypeRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async ({
            data,
            params: { showId, ticketTypeId }
        }: UserShowTicketTypeRequest) => {
            const response = await axios.patch<TicketType>(
                getShowTicketTypeUrl({
                    showId,
                    ticketTypeId
                }),
                data
            );

            return response.data;
        }
    });
};

export const useUserShowTicketsQuery = ({ showId }: ShowParams) => {
    return useQuery<Ticket[], FetchResponseError>({
        queryKey: [usersQueryKeys.USER_SHOW_TICKETS, showId],
        queryFn: async () => {
            const response = await axios.get<Ticket[]>(
                getShowTicketsUrl({
                    showId
                })
            );

            return response.data;
        }
    });
};

export const useUserShowPayoutQuery = ({ showId }: ShowParams) => {
    return useQuery<SalesSummary, FetchResponseError>({
        queryKey: [usersQueryKeys.USER_SHOW_PAYOUT, { showId }],
        queryFn: async () => {
            const response = await axios.get<SalesSummary>(
                getShowPayoutUrl({
                    showId
                })
            );

            return response.data;
        }
    });
};

export const useUserShowPayoutMutation = (
    options?: UseMutationOptions<{}, FetchResponseError, ShowParams>
) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: async ({ showId }) => {
            const response = await axios.post<{}>(
                getShowPayoutUrl({
                    showId
                })
            );

            return response.data;
        },
        onSuccess: (data, { showId }) => {
            queryClient.invalidateQueries([
                usersQueryKeys.USER_SHOW_PAYOUT,
                { showId }
            ]);
        }
    });
};

type Timestamp = number;

type StripeAccountLink = {
    url: string;
    object: 'account_link';
    created: Timestamp;
    // eslint-disable-next-line camelcase
    expires_at: Timestamp;
};
export const useUserStripeCreateMutation = (
    options?: UseMutationOptions<
        StripeAccountLink,
        FetchResponseError,
        {
            showId: ShowId;
        }
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async ({ showId }) => {
            const response = await axios.post<StripeAccountLink>(
                URIS.users.stripe.index,
                {
                    showId
                }
            );

            return response.data;
        }
    });
};

export const useUserStripeUpdateMutation = (
    options?: UseMutationOptions<StripeAccount, FetchResponseError, unknown>
) => {
    return useMutation({
        ...options,
        mutationFn: async () => {
            const response = await axios.post<StripeAccount>(
                URIS.users.stripe.created
            );

            return response.data;
        }
    });
};

export type ShowsPostOrPatchRequest = {
    address: string;
    artists?: Artist[];
    city: string;
    countryId?: CountryId;
    description: string;
    djs?: Dj[];
    feeHandling: ShowFeeHandling | '';
    id?: ShowId;
    image: string;
    startDate: string;
    startTime: string;
    stateId?: StateId;
    stopDate: string;
    stopTime: string;
    ticketTypes: TicketType[];
    title: string;
    venue: string;
};

export const useShowsCreateMutate = (
    options?: UseMutationOptions<
        Show,
        FetchResponseError,
        ShowsPostOrPatchRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async (data: ShowsPostOrPatchRequest) => {
            const response = await axios.post<
                Show,
                AxiosResponseWithData<Show>
            >(getUserShowsUrl(), data);

            return response.data;
        }
    });
};

export const useShowUpdateMutate = (
    options?: UseMutationOptions<
        Show,
        FetchResponseError,
        ShowsPostOrPatchRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async (data: ShowsPostOrPatchRequest) => {
            const response = await axios.patch<
                Show,
                AxiosResponseWithData<Show>
            >(
                getUserShowsUrl({
                    showId: data.id
                }),
                data
            );

            return response.data;
        }
    });
};

type ShowsPublishRequest = ShowParams;
export const useUserShowPublishMutate = (
    options?: UseMutationOptions<Show, FetchResponseError, ShowsPublishRequest>
) => {
    return useMutation({
        ...options,
        mutationFn: async ({ showId }: ShowsPublishRequest) => {
            const response = await axios.post<
                Show,
                AxiosResponseWithData<Show>
            >(
                getUserShowPublishUrl({
                    showId
                })
            );

            return response.data;
        }
    });
};

export const useUserShowQuery = (showId: ShowId) => {
    return useQuery<Show>({
        queryKey: [usersQueryKeys.USER_SHOWS, showId],
        queryFn: async () => {
            const response = await axios.get<Show>(
                getUserShowsUrl({
                    showId
                }),
                {
                    params: {
                        fields: [
                            'artists',
                            'djs',
                            'ticket_types',
                            'country',
                            'state',
                            'promoter'
                        ].join()
                    }
                }
            );

            return response.data;
        },
        enabled: Boolean(showId)
    });
};

type ShowBookResponse = {
    // eslint-disable-next-line camelcase
    show_owner: boolean;
};

type ShowBookRequest = {
    showId: ShowId;
    data: {
        transactionId: string;
        payment: PaymentOptions;
        amount: number;
        ticketTypes: TicketTypesById;
        currency: Currency;
        couponCode?: string;
    };
};

export const useShowOrderMutation = (
    options?: UseMutationOptions<
        ShowBookResponse,
        FetchResponseError,
        ShowBookRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async ({ showId, data }) => {
            const response = await axios.post<ShowBookResponse>(
                getShowOrderUrl(),
                {
                    ...data,
                    showId
                }
            );

            return response.data;
        }
    });
};

export const useShowBookMutation = (
    options?: UseMutationOptions<
        ShowBookResponse,
        FetchResponseError,
        ShowBookRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async ({ showId, data }) => {
            const response = await axios.post<ShowBookResponse>(
                getShowBookUrl({
                    showId
                }),
                data
            );

            return response.data;
        }
    });
};

export const useMusicBookMutation = (
    options?: UseMutationOptions<
        Order,
        FetchResponseError,
        {
            transactionId: string;
            payment: PaymentOptions;
            albums?: { id: number; price: string }[];
            tracks?: { id: number; price: string }[];
            amount: number;
            currency: Currency;
        }
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async data => {
            const response = await axios.post<Order>(
                URIS.users.music.orders,
                data
            );

            return response.data;
        }
    });
};

export const useMusicOrderMutation = (
    options?: UseMutationOptions<
        Order,
        FetchResponseError,
        {
            transactionId: string;
            payment: PaymentOptions;
            albums?: { id: number; price: string }[];
            tracks?: { id: number; price: string }[];
            amount: number;
            currency: Currency;
        }
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async data => {
            const response = await axios.post<Order>(URIS.orders.music, data);

            return response.data;
        }
    });
};

export const useAdminUserUpdateMutation = (
    options?: UseMutationOptions<
        User,
        FetchResponseError,
        {
            data: Partial<
                Pick<User, 'firstName' | 'lastName'> & {
                    commission: number;
                    type: string;
                    status: string;
                }
            >;
            params: {
                userId: UserId;
            };
        }
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async ({ data, params: { userId } }) => {
            const response = await axios.patch<User>(
                getUserUpdateUrl({
                    userId
                }),
                data
            );

            return response.data;
        }
    });
};

export type UserProfileFields =
    | 'about'
    | 'city'
    | 'firstName'
    | 'gender'
    | 'image'
    | 'lastName'
    | 'phoneNumber'
    | 'subscription'
    | 'type';
export type UserPostOrPatchRequest = Pick<User, UserProfileFields> & {
    stateId?: StateId;
    countryId?: CountryId;
};

export const useUserUpdateMutation = (
    options?: UseMutationOptions<
        User,
        FetchResponseError,
        UserPostOrPatchRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async (data: UserPostOrPatchRequest) => {
            const response = await axios.patch<User>(URIS.users.current, data);

            return response.data;
        }
    });
};

export const useUsersQuery = () => {
    return useQuery<User[], FetchResponseError>({
        queryKey: [usersQueryKeys.USERS],
        queryFn: async () => {
            const response = await axios.get<User[]>(URIS.users.index, {
                params: {
                    fields: [
                        'commission',
                        'verification',
                        'artist.contract'
                    ].join()
                }
            });

            return response.data;
        },
        keepPreviousData: true
    });
};

export const useUserVerificationMutation = (
    options?: UseMutationOptions<
        User,
        FetchResponseError,
        {
            data: Partial<{
                message: string;
            }>;
            params: {
                userId: UserId;
            };
        }
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async ({ data, params: { userId } }) => {
            const response = await axios.post<User>(
                getUserVerificationUrl({
                    userId
                }),
                data
            );

            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries([usersQueryKeys.USERS]);
        }
    });
};

export const useUserBankMutation = (
    options?: UseMutationOptions<User, FetchResponseError, Bank>
) => {
    return useMutation({
        ...options,
        mutationFn: async data => {
            const response = await axios.put<User>(URIS.users.bank, data);

            return response.data;
        }
    });
};

export const useOrganizerMutation = (
    options?: UseMutationOptions<User, FetchResponseError, Promoter>
) => {
    return useMutation({
        ...options,
        mutationFn: async data => {
            const response = await axios.put<User>(
                URIS.users.organizer.index,
                data
            );

            return response.data;
        }
    });
};

type TrackDownloadRequest = number[];

type TrackDownloadResponse = {
    location: string;
};
export const useTrackDownloadMutation = (
    options?: UseMutationOptions<
        TrackDownloadResponse,
        FetchResponseError,
        TrackDownloadRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async (data: TrackDownloadRequest) => {
            const response = await axios.post<
                TrackDownloadRequest,
                AxiosResponseWithData<TrackDownloadResponse>
            >(URIS.users.download, data);

            return response.data;
        }
    });
};

type UserGetOptions = {
    loginType: 'Auto' | 'Manual' | 'SmartLock' | 'Social';
};

export const useUserQuery = (options: UserGetOptions) => {
    return useQuery<User, FetchResponseError>({
        queryKey: [usersQueryKeys.USER],
        queryFn: async () => {
            const response = await axios.get<User>(URIS.users.current);

            return response.data;
        },
        enabled: false,
        ...options
    });
};

export const useUserPlaylistLikesQuery = (
    playlistId: PlaylistId,
    options: UseQueryOptions<LikePlaylistResponse>
) => {
    const url = getUserPlaylistLikesUrl({ playlistId });

    return useQuery<LikePlaylistResponse>({
        queryKey: [usersQueryKeys.USER_PLAYLIST_LIKES, { playlistId }],
        queryFn: async () => {
            const response = await axios.get<LikePlaylistResponse>(url);

            return response.data;
        },
        ...options
    });
};

type LikePlaylistRequest = {
    data: {
        status: boolean;
    };
};

type LikePlaylistResponse = {
    status: boolean;
    playlistId: PlaylistId;
};

export const useUpdatePlaylistLikeMutation = (
    playlistId: PlaylistId,
    options?: UseMutationOptions<
        LikePlaylistResponse,
        FetchResponseError,
        LikePlaylistRequest
    >
) => {
    const queryClient = useQueryClient();
    const url = getUserPlaylistLikesUrl({ playlistId });

    return useMutation({
        ...options,
        mutationFn: async ({ data }) => {
            const response = await axios.put<LikePlaylistResponse>(url, data);

            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries([
                usersQueryKeys.USER_PLAYLIST_LIKES,
                { playlistId }
            ]);

            queryClient.invalidateQueries([
                usersQueryKeys.USER_PLAYLISTS,
                { playlistId }
            ]);

            queryClient.invalidateQueries([
                playlistQueryKeys.PLAYLIST,
                { playlistId }
            ]);
        }
    });
};

type DeleteLikePlaylistResponse = {
    playlistId: PlaylistId;
};

export const useDeletePlaylistLikeMutation = (
    playlistId: PlaylistId,
    options?: UseMutationOptions<DeleteLikePlaylistResponse, FetchResponseError>
) => {
    const queryClient = useQueryClient();
    const url = getUserPlaylistLikesUrl({ playlistId });

    return useMutation({
        ...options,
        mutationFn: async () => {
            const response = await axios.delete<
                DeleteLikePlaylistResponse,
                AxiosResponseWithData<DeleteLikePlaylistResponse>
            >(url);

            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries([
                usersQueryKeys.USER_PLAYLIST_LIKES,
                { playlistId }
            ]);

            queryClient.invalidateQueries([
                usersQueryKeys.USER_PLAYLISTS,
                { playlistId }
            ]);

            queryClient.invalidateQueries([
                playlistQueryKeys.PLAYLIST,
                { playlistId }
            ]);
        }
    });
};

type DjBookingsResponse = {
    data: DjBooking[];
    meta: PaginationMeta;
};
export const useUserDjBookingsQuery = (
    { page, perPage }: PaginationParams,
    bookingId?: DjBookingId
) =>
    useQuery<DjBookingsResponse, FetchResponseError>({
        queryKey: [usersQueryKeys.USER_DJ_BOOKINGS, { page, perPage }],
        queryFn: async () => {
            const { data, meta } = await axios.get<
                DjBookingsResponse,
                DjBookingsResponse
            >(getUserDjBookingUrl({ bookingId }), {
                params: {
                    page,
                    perPage,
                    fields: [
                        'country',
                        'state',
                        'dj.user',
                        'dj.service',
                        'dj.user.profile.country',
                        'dj.user.profile.state',
                        'addons'
                    ].join()
                }
            });

            return {
                data,
                meta
            };
        },
        keepPreviousData: true
    });

export const useUserDjBookingQuery = (
    bookingId: DjBookingId,
    fields: string[] = []
) => {
    return useQuery<DjBooking, FetchResponseError, DjBooking>(
        [usersQueryKeys.USER_BOOKING, { bookingId }, { fields }],
        async () => {
            const response = await axios.get<DjBooking>(
                getUserDjBookingUrl({
                    bookingId
                }),
                {
                    params: {
                        fields: fields
                            .concat([
                                'order',
                                'country',
                                'state',
                                'dj.user',
                                'dj.service.locations',
                                'dj.user.profile.country',
                                'dj.user.profile.state',
                                'addons'
                            ])
                            .join()
                    }
                }
            );

            return response.data;
        }
    );
};

type DjBookingOrderRequest = {
    bookingId: DjBookingId;
    transactionId: string;
    payment: PaymentOptions;
    amount: number;
    currency: Currency;
};

export const useBookDjBookingMutation = (
    options?: UseMutationOptions<
        Order,
        FetchResponseError,
        DjBookingOrderRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async data => {
            const response = await axios.post<Order>(
                URIS.users.bookings.orders,
                data
            );

            return response.data;
        }
    });
};

export const useDjBookingOrderMutation = (
    options?: UseMutationOptions<
        Order,
        FetchResponseError,
        DjBookingOrderRequest
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async data => {
            const response = await axios.post<Order>(
                getDjBookingOrderUrl(),
                data
            );

            return response.data;
        }
    });
};

export const useUserRecentlyPlayedQuery = () => {
    return useQuery<Recents, FetchResponseError>({
        queryKey: [usersQueryKeys.USER_RECENTLY_PLAYED, 'recently-played'],
        queryFn: async () => {
            const response = await axios.get<Recents>(
                URIS.users.recentlyPlayed
            );

            return response.data;
        }
    });
};

export const useUserPlaylistItemsReorderMutation = (
    playlistId: PlaylistId,
    options?: UseMutationOptions<
        Track[],
        FetchResponseError,
        Pick<Playlist, 'tracks'>
    >
) => {
    const queryClient = useQueryClient();
    const url = getPlaylistTracksUrl({
        playlistId
    });

    return useMutation({
        ...options,
        mutationFn: async ({ tracks = [] }: Pick<Playlist, 'tracks'>) => {
            const response = await axios.patch<Track[]>(url, {
                tracks: tracks.map(track => track.id)
            });

            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries([
                usersQueryKeys.USER_PLAYLISTS,
                { playlistId }
            ]);
        }
    });
};

export const useUserPreferencesMutation = (
    options?: UseMutationOptions<
        AccountPreferences,
        FetchResponseError,
        AccountPreferences
    >
) => {
    return useMutation({
        ...options,
        mutationFn: async (preferences: AccountPreferences) => {
            const response = await axios.put<AccountPreferences>(
                URIS.users.preferences,
                preferences
            );

            return response.data;
        }
    });
};

export const useUserPreferencesQuery = (
    options?: UseQueryOptions<AccountPreferences, FetchResponseError>
) => {
    const isLoggedIn = useIsLoggedIn();

    return useQuery<AccountPreferences, FetchResponseError>({
        queryKey: [usersQueryKeys.USER_PREFERENCES],
        queryFn: async ({ signal }) => {
            const response = await axios.get<AccountPreferences>(
                URIS.users.preferences,
                {
                    signal
                }
            );

            return response.data;
        },
        ...options,
        staleTime: 1000 * 60 * 60 * 0.5,
        enabled: isLoggedIn
    });
};

export const useUserReferralCodeMutation = (
    options?: UseMutationOptions<ReferralCode, FetchResponseError>
) => {
    return useMutation({
        ...options,
        mutationFn: async () => {
            const response = await axios.patch<ReferralCode>(
                URIS.users.referralCode
            );

            return response.data;
        }
    });
};

type OtpRequest = {
    email: string;
    otp: string;
};

export const useUserOtpMutation = (
    options?: UseMutationOptions<OtpRequest, FetchResponseError, OtpRequest>
) => {
    return useMutation({
        ...options,
        mutationFn: async (data: OtpRequest) => {
            const response = await axios.patch<OtpRequest>(
                URIS.users.otpActivation,
                data
            );

            return response.data;
        }
    });
};

export const resendOtp = async (data: Pick<User, 'email'>) => {
    const response = await axios.patch<string>(URIS.users.resendOtp, data);

    return response.data;
};
