import type { ChangeEvent, CSSProperties } from 'react';
import {
    useCallback,
    useRef,
    useEffect,
    useState,
    useDeferredValue
} from 'react';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import type { OutlinedTextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SearchIcon from '@mui/icons-material/Search';
import { useLayout } from 'hooks/use-layout';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            color: theme.palette.common.black
        },
        text: {
            marginLeft: 10,
            marginRight: 10
        },
        textInput: {
            margin: 'auto',
            height: '40px'
        },
        searchArrow: {
            color: theme.palette.primary.main
        }
    })
);

type Props = {
    isSearchOpen: boolean;
    showBackButton?: boolean;
    onSearch: (keyword: string) => void;
    onOpen: () => void;
    onClose: () => void;
};

const Input = ({
    isSearchOpen,
    showBackButton,
    onSearch,
    onClose,
    onOpen
}: Props) => {
    const classes = useStyles();
    const node = useRef<HTMLInputElement>(null);
    const { isMobile } = useLayout();
    const [value, setValue] = useState('');
    const deferredValue = useDeferredValue((value ?? '').trim());

    useEffect(() => {
        onSearch(deferredValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deferredValue]);

    useEffect(() => {
        if (!isSearchOpen && value) {
            setValue('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearchOpen]);

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const query = e.target.value;

            if (value !== query) {
                setValue(query);
            }
        },
        [value]
    );

    const onSearchClearOrClose = useCallback(() => {
        if (value.length === 0) {
            onClose();
        } else {
            setValue('');
        }

        if (node.current) {
            node.current.value = '';

            node.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value.length]);

    const onSearchIconClick = useCallback(() => {
        onOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const style: CSSProperties = {
        justifyContent: 'flex-end',
        maxWidth: '500px',
        width: '100%'
    };

    if (isMobile && !isSearchOpen) {
        style.width = 'auto';
        delete style.maxWidth;
    }

    const InputProps = {
        ...(isMobile && { disableUnderline: true })
    };

    const textProps: OutlinedTextFieldProps | object = {
        ...(isMobile && { variant: 'standard' })
    };

    const placeholder = 'Search by artist, album, dj or song';

    return (
        <div className={'flex flex-direction-row'} style={style}>
            {isSearchOpen && (
                <div className='arrow-back-wrapper flex-fixed'>
                    <IconButton
                        aria-label='close search'
                        onClick={onClose}
                        className={classes.searchArrow}
                        data-test='arrow-back'
                        size='large'
                    >
                        <ArrowBack />
                    </IconButton>
                </div>
            )}
            {(isSearchOpen || !isMobile) && (
                <div className='flex-grow align-center'>
                    <TextField
                        {...textProps}
                        autoFocus={isMobile}
                        className={`${classes.text}`}
                        fullWidth
                        placeholder={placeholder}
                        onChange={onChange}
                        InputProps={{
                            inputProps: {
                                autoComplete: 'off',
                                'data-test-id': 'search-input'
                            },
                            classes: {
                                root: classes.textInput
                            },
                            startAdornment: !isMobile ? (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ) : undefined,
                            endAdornment: (
                                <InputAdornment
                                    position='end'
                                    onClick={onSearchClearOrClose}
                                >
                                    <IconButton
                                        edge='end'
                                        style={{
                                            width: 'auto',
                                            height: 'auto'
                                        }}
                                        size='large'
                                    >
                                        {value.length ? (
                                            <ClearAllIcon />
                                        ) : (
                                            <CloseIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            inputRef: node,
                            ...InputProps
                        }}
                        value={value}
                    />
                </div>
            )}
            {!isSearchOpen && isMobile && !showBackButton && (
                <IconButton
                    className='search-icon flex-pull-right'
                    aria-label='search'
                    onClick={onSearchIconClick}
                    data-test-id='search-icon'
                    size='large'
                >
                    <SearchIcon />
                </IconButton>
            )}
        </div>
    );
};

export default Input;
