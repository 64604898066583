import { lazy } from 'react';
import type { QueryClient } from '@tanstack/react-query';
import type { AlbumParams } from 'types';
import { albumQuery } from 'queries/albums';

export default lazy(() => import(/* webpackChunkName: "album" */ './Album'));

export const albumLoader = (queryClient: QueryClient) => {
    return async ({ params }: { params: AlbumParams }) => {
        return queryClient.ensureQueryData(
            albumQuery(
                {
                    artistSlug: params.artistSlug,
                    albumSlug: params.albumSlug
                },
                true
            )
        );
    };
};
