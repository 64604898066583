import reduce from 'lodash/reduce';
import {
    MUSIC_CART_ADD_ITEM,
    MUSIC_CART_REMOVE_ITEM,
    MUSIC_CART_CLEAR
} from 'actions/types';
import type { MusicCart, Track, Album } from 'types';
import type Actions from 'actions';
import localStorage from 'shared/storage/localStorage';
import logger from 'utilities/logger';

const musicCart = localStorage.get<MusicCart>('music-cart');
const initialState: MusicCart = musicCart || {
    music: {
        tracks: {},
        albums: {}
    },
    quantity: 0,
    totalAmount: 0,
    currency: 'NGN'
};

const reducers = (state = initialState, action: Actions): MusicCart => {
    switch (action.type) {
        case MUSIC_CART_ADD_ITEM: {
            const { entity, item, currency } = action.payload;
            const music = state.music;
            let count = state.quantity;

            if (item.id) {
                const isAlbum = entity === 'albums';

                if (
                    !Object.keys(
                        isAlbum ? music.albums : music.tracks
                    ).includes(String(item.id))
                ) {
                    ++count;
                }

                if (isAlbum) {
                    music.albums = {
                        ...music.albums,
                        [item.id]: item as Album
                    };
                } else {
                    music.tracks = {
                        ...music.tracks,
                        [item.id]: item as Track
                    };
                }
            } else {
                logger.error('item.id is not defined');
            }

            const quantity = count;
            const items: (Album | Track)[] = Object.values(
                state.music.tracks
            ).concat(Object.values(state.music.albums) as any[]);

            const totalAmount = reduce(
                items,
                (sum, item) => {
                    const price =
                        (item as Track).album?.price?.track ??
                        (item as Album)?.price?.album;

                    return sum + Number(price);
                },
                0
            );

            return {
                ...state,
                ...{
                    music,
                    quantity,
                    totalAmount: Number(totalAmount.toFixed(2)),
                    currency
                }
            };
        }

        case MUSIC_CART_REMOVE_ITEM: {
            const { entity, item } = action.payload;
            const music = state.music;
            let count = state.quantity;

            if (item.id) {
                const isAlbum = entity === 'albums';

                if (
                    Object.keys(isAlbum ? music.albums : music.tracks).includes(
                        String(item.id)
                    )
                ) {
                    --count;

                    if (isAlbum) {
                        delete music.albums[item.id];
                    } else {
                        delete music.tracks[item.id];
                    }
                }
            } else {
                logger.error('item.id is not defined');
            }

            const quantity = count;
            const items: (Album | Track)[] = Object.values(
                state.music.tracks
            ).concat(Object.values(state.music.albums) as any[]);

            const totalAmount = reduce(
                items,
                (sum, item) => {
                    const price =
                        (item as Track).album?.price?.track ??
                        (item as Album)?.price?.album;

                    return sum + Number(price);
                },
                0
            );

            return {
                ...state,
                ...{
                    music,
                    quantity,
                    totalAmount: Number(totalAmount.toFixed(2))
                }
            };
        }

        case MUSIC_CART_CLEAR: {
            return {
                music: {
                    tracks: {},
                    albums: {}
                },
                quantity: 0,
                totalAmount: 0,
                currency: 'NGN'
            };
        }

        default:
            return state;
    }
};

export default reducers;
