import type {
    PlayerPlaylist,
    PlayerParams,
    Track,
    PlayerRepeat,
    TrackId
} from 'types';
import {
    LOAD,
    ON_ADD_TO_QUEUE,
    ON_BUFFER_CHANGE,
    ON_LOAD,
    ON_CAST_CONNECT,
    ON_CAST_DISCONNECT,
    ON_CAST_DEVICE_NAME_CHANGE,
    ON_CAST_STATE_CHANGE,
    ON_ERROR,
    ON_NEXT,
    ON_PAUSE,
    ON_PAUSED,
    ON_PLAY,
    ON_PLAYER_READY,
    ON_PLAYING,
    ON_PLAYLIST_ITEM,
    ON_REMOVE_FROM_QUEUE,
    ON_PREV,
    ON_REPEAT,
    ON_SHUFFLE,
    ON_STOP
} from '../types/player';

export const onPlay = ({ trackId }: { trackId?: TrackId } = {}) =>
    ({
        type: ON_PLAY,
        payload: {
            trackId
        }
    } as const);

export const onNext = () =>
    ({
        type: ON_NEXT
    } as const);

export const onPrev = () =>
    ({
        type: ON_PREV
    } as const);

export const onPause = () =>
    ({
        type: ON_PAUSE
    } as const);

export const onStop = () =>
    ({
        type: ON_STOP
    } as const);

export const load = ({
    playlist,
    autoPlay = false,
    trackId,
    params
}: {
    playlist: PlayerPlaylist;
    autoPlay?: boolean;
    trackId?: TrackId;
    params: PlayerParams;
}) =>
    ({
        type: LOAD,
        payload: {
            playlist,
            autoPlay,
            trackId,
            params
        }
    } as const);

export const onReady = () =>
    ({
        type: ON_PLAYER_READY
    } as const);

export const onPlaying = () =>
    ({
        type: ON_PLAYING
    } as const);

export const onPaused = () =>
    ({
        type: ON_PAUSED
    } as const);

export const onError = ({ message }: { message: string }) =>
    ({
        type: ON_ERROR,
        payload: {
            message
        }
    } as const);

export const onLoading = () =>
    ({
        type: ON_LOAD
    } as const);

export const onBufferChange = ({ bufferPercent }: { bufferPercent: number }) =>
    ({
        type: ON_BUFFER_CHANGE,
        payload: {
            bufferPercent
        }
    } as const);

export const onPlaylistItem = ({ trackId }: { trackId: TrackId }) =>
    ({
        payload: {
            trackId
        },
        type: ON_PLAYLIST_ITEM
    } as const);

export const onShuffle = () =>
    ({
        type: ON_SHUFFLE
    } as const);

export const onRepeat = ({ repeat }: { repeat: PlayerRepeat }) =>
    ({
        type: ON_REPEAT,
        payload: {
            repeat
        }
    } as const);

export const onCastStateChange = ({
    value
}: {
    value: cast.framework.CastState;
}) =>
    ({
        type: ON_CAST_STATE_CHANGE,
        payload: {
            value
        }
    } as const);

export const onCastDeviceNameChange = ({
    deviceName
}: {
    deviceName: string;
}) =>
    ({
        type: ON_CAST_DEVICE_NAME_CHANGE,
        payload: {
            deviceName
        }
    } as const);

export const onCastConnect = ({ deviceName }: { deviceName: string }) =>
    ({
        type: ON_CAST_CONNECT,
        payload: {
            deviceName
        }
    } as const);

export const onCastDisconnect = () =>
    ({
        type: ON_CAST_DISCONNECT
    } as const);

export const onAddToQueue = ({ tracks }: { tracks: Track[] }) =>
    ({
        type: ON_ADD_TO_QUEUE,
        payload: {
            tracks
        }
    } as const);

export const onRemoveFromQueue = ({ trackId }: { trackId: TrackId }) =>
    ({
        type: ON_REMOVE_FROM_QUEUE,
        payload: {
            trackId
        }
    } as const);
