import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import type { ColorSchemeOption } from 'types';
import { usePreferences } from 'hooks/use-preferences';
import getTheme from 'theme';

// value should match the theme value in index.html
let defaultTheme: ColorSchemeOption = 'dark';

if (document.documentElement) {
    defaultTheme = document.documentElement.dataset.theme as ColorSchemeOption;
}

type Props = { children: ReactNode };

const ThemeContext = createContext<{
    colorScheme: ColorSchemeOption;
    setColorScheme: (value: ColorSchemeOption) => void;
}>({
    colorScheme: 'dark',
    setColorScheme: () => {
        throw new Error('setColorScheme is not defined');
    }
});

export const ThemeContextProvider = ({ children }: Props) => {
    const [getPreference, setPreference] = usePreferences();
    const colorScheme = getPreference<ColorSchemeOption>(
        'shell.theme',
        defaultTheme
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setColorScheme = (value: ColorSchemeOption) => {
        setPreference<ColorSchemeOption>('shell.theme', value);
    };

    const value = useMemo(
        () => ({
            colorScheme,
            setColorScheme
        }),
        [colorScheme, setColorScheme] // deepscan-disable-line REACT_USELESS_DEPENDENCY_OF_HOOK
    );

    return (
        <ThemeContext.Provider value={value}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getTheme(colorScheme)}>
                    <CssBaseline />
                    {children}
                </ThemeProvider>
            </StyledEngineProvider>
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);

    return context;
};

ThemeContext.displayName = 'ThemeContext';

export default ThemeContext;
