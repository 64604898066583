export const getRangesTotal = (ranges: TimeRanges) => {
    let sum = 0;

    for (let i = 0; i < ranges.length; i++) {
        const start = ranges.start(i);
        const end = ranges.end(i);
        const total = end - start;

        sum += total;
    }

    return sum;
};
