import type { TicketType, Currency } from 'types';
import { CART_ADD_ITEM, CART_CLEAR } from '../types';

export const cartAddItem = (id: string, item: TicketType, currency: Currency) =>
    ({
        type: CART_ADD_ITEM,
        payload: {
            id,
            item,
            currency
        }
    } as const);

export const cartClear = () =>
    ({
        type: CART_CLEAR
    } as const);
