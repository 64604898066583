import type { CSSProperties } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
    size?: number;
    style?: CSSProperties;
};

const Spinner = ({ size = 50, style }: Props) => (
    <div
        data-test-id='spinner'
        style={style}
        className='spinner flex flex-grow align-center'
    >
        <div className='preloader-wrapper'>
            <CircularProgress
                aria-busy='true'
                aria-label='loading'
                size={size}
            />
        </div>
    </div>
);

export default Spinner;
