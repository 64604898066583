export const FETCH_TOKEN = 'FETCH_TOKEN';
export const FETCH_TOKEN_ERROR = 'FETCH_TOKEN_ERROR';
export const FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const FETCH_LOGOUT = 'FETCH_LOGOUT';
export const FETCH_LOGOUT_ERROR = 'FETCH_LOGOUT_ERROR';
export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';

export const FETCH_BEGIN = '@@api/FETCH_BEGIN';
export const FETCH_END = '@@api/FETCH_END';
export const FETCH_FAIL = '@@api/FETCH_FAIL';
export const FETCH = 'FETCH';
