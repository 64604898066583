import type { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useEventsMutation } from 'queries/global';
import type { AppEvent } from 'types/app-event';
import type { AppDispatch } from 'store';
import { notificationClose } from 'actions';
import ButtonLink from './ButtonLink';

type Props = {
    children?: ReactNode;
    to: string;
    event: AppEvent;
    isClose?: boolean;
};

const TrackedButton = ({
    children,
    to,
    event,
    isClose = false,
    ...props
}: Props) => {
    const { mutate } = useEventsMutation();
    const dispatch = useDispatch<AppDispatch>();

    const onClick = () => {
        mutate(event);

        if (isClose) {
            dispatch(notificationClose());
        }
    };

    return (
        <ButtonLink
            size={'small'}
            className='no-underline'
            variant='contained'
            sx={{
                textTransform: 'none',
                paddingX: 2
            }}
            onClick={onClick}
            to={to}
        >
            {children}
        </ButtonLink>
    );
};

export default TrackedButton;
