import type { ReactNode, RefObject } from 'react';
import { useMemo, createContext } from 'react';
import useComponentSize from '@rehooks/component-size';
import { useUser } from 'hooks/use-user';
import type { User } from 'types';

type Context = {
    user?: User | null;
    size?: {
        width: number;
        height: number;
    };
};

type Props = { children: ReactNode; pageRef: RefObject<HTMLDivElement | null> };

const ShellContext = createContext<Context | undefined>(undefined);

export const ShellContextProvider = ({ children, pageRef }: Props) => {
    const user = useUser();
    const size = useComponentSize(pageRef);
    const value = useMemo(
        () => ({
            user,
            size
        }),
        [user, size]
    );

    return (
        <ShellContext.Provider value={value}>{children}</ShellContext.Provider>
    );
};

ShellContext.displayName = 'ShellContext';

export default ShellContext;
