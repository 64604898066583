import type { Album, Currency, Track } from 'types';
import {
    MUSIC_CART_ADD_ITEM,
    MUSIC_CART_REMOVE_ITEM,
    MUSIC_CART_CLEAR
} from '../types';

export const musicCartAddItem = (
    entity: 'albums' | 'tracks',
    item: Album | Track,
    currency: Currency
) =>
    ({
        type: MUSIC_CART_ADD_ITEM,
        payload: {
            entity,
            item,
            currency
        }
    } as const);

export const musicCartRemoveItem = (
    entity: 'albums' | 'tracks',
    item: Album | Track,
    currency: Currency
) =>
    ({
        type: MUSIC_CART_REMOVE_ITEM,
        payload: {
            entity,
            item,
            currency
        }
    } as const);

export const musicCartClear = () =>
    ({
        type: MUSIC_CART_CLEAR
    } as const);
