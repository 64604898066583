import omit from 'lodash/omit';
import type {
    Playlist,
    PlaylistId,
    PlayerParams,
    Track,
    Album,
    Artist
} from 'types';

export const checkIfPlaylistNameExists = (
    playlists: Playlist[],
    name: string
) => {
    return Boolean(
        playlists.find(
            playlist =>
                name.toLowerCase().trim() === playlist.name.toLowerCase().trim()
        )
    );
};

export const getPlaylistIndex = (
    playlists: Playlist[],
    playlistId: PlaylistId
) => {
    return playlists.findIndex(item => playlistId === item.id);
};

export const isSamePlaylist = (
    leftParams: PlayerParams,
    rightParams: PlayerParams
) =>
    leftParams.id === rightParams.id &&
    leftParams.albumSlug === rightParams.albumSlug &&
    leftParams.artistSlug === rightParams.artistSlug;

export const toPlaylist = (
    tracks: Track[] = [],
    album: Album,
    artist: Artist
) => {
    return tracks.map(track => {
        const item = {
            ...track,
            album: omit(album, ['artist', 'artists', 'tracks']),
            artist: omit(artist, ['albums', 'tracks'])
        };

        return item;
    });
};
