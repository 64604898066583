import { useEffect, useState } from 'react';
import getByKey from 'lodash/get';
import setByKey from 'lodash/set';
import merge from 'lodash/merge';
import type {
    AccountPreferences,
    DevicePreferences,
    Preferences,
    PreferencesPaths,
    AccountPreferencesPaths,
    DevicePreferencesPaths
} from 'types';
import {
    useUserPreferencesMutation,
    useUserPreferencesQuery
} from 'queries/users';
import logger from 'utilities/logger';
import {
    combinePreferences,
    getIsAccountKey,
    getIsDeviceKey,
    getPreferencesKey,
    getPreferencesVersion,
    getPreferenceValuesFor
} from 'helpers/preferences';
import { useLocalStorage } from './use-local-storage';
import { useUser } from './use-user';

export const usePreferences = () => {
    const [isReady, setIsReady] = useState(true);
    const user = useUser();
    const { data: accountPreferences, isFetched } = useUserPreferencesQuery();
    const [devicePreferences, setDevicePreferences] = useLocalStorage<
        DevicePreferences | undefined
    >(getPreferencesKey(user.id), undefined, getPreferencesVersion());
    const userPreferencesMutation = useUserPreferencesMutation();
    const [preferences, setPreferences] = useState<Preferences>(
        combinePreferences(devicePreferences, accountPreferences)
    );
    const isLoggedIn = Boolean(user.id);

    useEffect(() => {
        setPreferences(
            combinePreferences(devicePreferences, accountPreferences)
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountPreferences, devicePreferences]);

    useEffect(() => {
        if (isReady) {
            if (isLoggedIn && isFetched) {
                setIsReady(false);
            } else if (!isLoggedIn) {
                setIsReady(false);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferences]);

    const get = <T>(key: PreferencesPaths, defaultValue: T) => {
        return getByKey(preferences, key, defaultValue) as T;
    };

    const set = async <T>(key: PreferencesPaths, value: T) => {
        const newPreferences = setByKey(merge({}, preferences), key, value);

        setPreferences(newPreferences);

        const isAccountKey = getIsAccountKey(key as AccountPreferencesPaths);
        const isDeviceKey = getIsDeviceKey(key as DevicePreferencesPaths);

        if (isLoggedIn && isAccountKey) {
            // save to server
            const accountPreferences =
                getPreferenceValuesFor<AccountPreferences>(
                    newPreferences,
                    'account'
                );

            try {
                await userPreferencesMutation.mutateAsync(accountPreferences);
            } catch (e) {
                logger.error(e);
            }
        }

        if (isDeviceKey && !(isLoggedIn && isAccountKey)) {
            // save to local storage
            const devicePreferences = getPreferenceValuesFor<DevicePreferences>(
                newPreferences,
                'device'
            );

            setDevicePreferences(devicePreferences);
        }

        if (!(isAccountKey || isDeviceKey)) {
            logger.warn(
                `No preference found for key: ${key} - isAccountKey=${isAccountKey}, isDeviceKey=${isDeviceKey} `
            );
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return [get, set, { isReady }] as [
        <T>(key: PreferencesPaths, defaultValue: T) => T,
        <T>(key: PreferencesPaths, value: T) => void,
        {
            isReady: boolean;
        }
    ];
};
