import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import ShareIcon from '@mui/icons-material/Share';
import { useDispatch } from 'react-redux';
import { onShare } from 'actions';
import type { AppDispatch } from 'store';

const socials = [
    {
        icon: <FacebookIcon />,
        style: {
            color: '#3b5998'
        },
        href: 'https://facebook.com/EpohMusic'
    },
    {
        icon: <TwitterIcon />,
        style: {
            color: '#00aced'
        },
        href: 'https://twitter.com/epoh_music'
    },
    {
        icon: <InstagramIcon />,
        style: {
            color: '#bc2a8d'
        },
        href: 'https://instagram.com/epoh_music'
    }
];

const supportsShare = Boolean(navigator.share);

const SocialLinks = () => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <div>
            <div
                className='flex flex-direction-row flex-row flex-fixed'
                style={{ marginTop: '5px' }}
            >
                {socials.map((social, index) => (
                    <a
                        key={`social-${index}`}
                        href={social.href}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <IconButton style={social.style} size='large'>
                            {social.icon}
                        </IconButton>
                    </a>
                ))}
                {supportsShare && (
                    <IconButton
                        onClick={e => {
                            e.preventDefault();

                            dispatch(
                                onShare({
                                    title: document.title
                                })
                            );
                        }}
                        size='large'
                    >
                        <ShareIcon />
                    </IconButton>
                )}
            </div>
            <Typography
                variant='body2'
                style={{
                    marginLeft: 16
                }}
            >
                Connect with us
            </Typography>
        </div>
    );
};

export default SocialLinks;
