import type { Snackbar, Alert, Feedback } from 'types';
import type { ShellState } from 'types/store';
import { getBrowserId, getSessionId } from 'helpers/app';
import { isPushNotificationPermissionGranted } from 'helpers/push-notification';

const snackbar: Snackbar = {
    isOpen: false,
    message: '',
    options: {}
};

const alert: Alert = {
    show: false,
    message: '',
    options: {}
};

const feedback: Feedback = {
    show: false,
    scope: 'music-play'
};

const initialState: ShellState = {
    alert,
    connection: 'online',
    sessionId: getSessionId(),
    browserId: getBrowserId(),
    errors: undefined,
    isFetching: false,
    isLoading: false,
    isLoggingIn: false,
    isPageReady: false,
    isPushEnabled: isPushNotificationPermissionGranted(),
    pendingSnackbars: [],
    isNotificationOpen: false,
    showBackButton: false,
    snackbar,
    tabIndex: 0,
    tabs: [],
    feedback
};

export default initialState;
