const isSupported = (type: 'localStorage') => {
    const storage = window[type];

    try {
        const x = '__storage_test__';

        storage.setItem(x, x);
        storage.removeItem(x);

        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            ([22, 1014].includes(e.code) ||
                // test name field too, because code might not be present
                // everything except Firefox
                ['QuotaExceededError', 'NS_ERROR_DOM_QUOTA_REACHED'].includes(
                    e.name
                )) &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0
        );
    }
};

export default isSupported;
