import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { ReactNode } from 'react';
import Cookies from 'overrides/js-cookie';
import ROUTES from 'shared/routes';
import { getIsLoggedIn } from 'selectors';
import type { PageRoute } from 'types';
import { useUser } from 'hooks/use-user';
import { isAllowed } from 'helpers/is-allowed';
import { isCypress } from 'helpers/env';
import bowser from 'overrides/bowser';
import Spinner from 'components/Spinner';
import { getRemoteConfigBooleanValue } from 'overrides/firebase.remote-config';

type Props = Omit<PageRoute, 'component' | 'key'> & {
    children: ReactNode;
};

const Restricted = ({
    children,
    access,
    restrictDirectAccess,
    indexable = false
}: Props) => {
    const isLoggedIn = useSelector(getIsLoggedIn);
    const user = useUser();
    const location = useLocation();
    const params = useParams();
    const isIndexable =
        indexable && (bowser.getPlatformType() === 'bot' || isCypress());
    const shouldRedirectToLogin = !isLoggedIn && !isIndexable;
    const shouldAutoLogin = Cookies.get('shouldAutoLogin') ?? false;
    const isKeepUserLoggedInEnabled =
        getRemoteConfigBooleanValue('KeepUserLoggedIn');
    const showSpinner = shouldAutoLogin && !isLoggedIn;

    if (isKeepUserLoggedInEnabled) {
        if (showSpinner) {
            return <Spinner />;
        }
    }

    if (shouldRedirectToLogin) {
        return (
            <Navigate
                to={ROUTES.LOGIN}
                state={{
                    from: location.pathname
                }}
                replace
            />
        );
    }

    const isUserAccess = access?.includes('user');
    const hasAccess = isUserAccess || isAllowed(user, access ?? [], false);

    if (!hasAccess) {
        return <Navigate to={ROUTES.HOME} replace />;
    }

    if (typeof restrictDirectAccess === 'function') {
        const pathname = restrictDirectAccess(
            location,
            params as Record<string, string>
        );

        if (pathname) {
            return <Navigate to={pathname} replace />;
        }
    }

    return <>{children}</>;
};

export default Restricted;
