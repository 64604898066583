import type { CookieAttributes } from 'js-cookie';

const { hostname } = globalThis.location;

const getDomain = () => {
    if (hostname === 'localhost') {
        return hostname;
    }

    return `.${hostname}`;
};

const cookieOptions: CookieAttributes = {
    expires: 90,
    sameSite: 'lax'
};

export const getCookieOptions = (overrides?: CookieAttributes) => {
    return {
        ...cookieOptions,
        ...overrides,
        domain: getDomain()
    };
};
