import { useEffect, useState } from 'react';
import localStorage from 'shared/storage/localStorage';

export const useLocalStorage = <T>(
    key: string,
    defaultValue: T,
    version?: string
) => {
    const getValue = () => {
        const item = localStorage.get<T>(key, version);

        return item ?? defaultValue;
    };
    const [storedValue, setStoredValue] = useState<T>(getValue());

    useEffect(() => {
        setStoredValue(getValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, version]);

    const setValue = (value: T) => {
        localStorage.set<T>(key, value, version);

        setStoredValue(value);
    };

    return [storedValue, setValue] as [T, (value: T) => void];
};
