import type { MessagePayload, Messaging } from 'firebase/messaging';
import {
    getMessaging,
    onMessage,
    getToken,
    deleteToken,
    isSupported
} from 'firebase/messaging';
import app from 'overrides/firebase';
import config from 'config';
import logger from 'utilities/logger';

export const getIsSupported = isSupported();

const getFirebaseMessaging = () => {
    return new Promise<Messaging>((resolve, reject) => {
        void getIsSupported.then(supported => {
            if (supported) {
                resolve(getMessaging(app));
            } else {
                reject(new Error('firebase/messaging is not supported'));
            }
        });
    });
};

export const onFirebaseMessage = (
    observer: (payload: MessagePayload) => void
) => {
    return getFirebaseMessaging()
        .then(messaging => {
            return onMessage(messaging, observer);
        })
        .catch(logger.error);
};

export const getFirebaseToken = async () => {
    return getFirebaseMessaging().then(async messaging => {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        // Get Instance ID token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        return getToken(messaging, {
            serviceWorkerRegistration,
            vapidKey: config.VAPID_KEY
        });
    });
};

export const deleteFirebaseToken = async () => {
    return getFirebaseMessaging().then(async messaging => {
        return deleteToken(messaging);
    });
};
