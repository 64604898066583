export const BACK_BUTTON_HIDE = '@@shell/BACK_BUTTON_HIDE';
export const BACK_BUTTON_SHOW = '@@shell/BACK_BUTTON_SHOW';
export const LOADING_START = '@@shell/LOADING_START';
export const LOADING_STOP = '@@shell/LOADING_STOP';
export const ON_CONNECTION = '@@shell/ON_CONNECTION';
export const ON_PAGE_READY = '@@shell/ON_READY';
export const ON_PUSH_SUBSCRIPTION = '@@shell/ON_PUSH_SUBSCRIPTION';
export const SNACKBAR_CLOSE = '@@shell/SNACKBAR_CLOSE';
export const SNACKBAR_OPEN = '@@shell/SNACKBAR_OPEN';
export const ALERT_CLOSE = '@@shell/ALERT_CLOSE';
export const ALERT_OPEN = '@@shell/ALERT_OPEN';
export const TAB_CHANGE = '@@shell/TAB_CHANGE';
export const FEEDBACK_DIALOG_OPEN = '@@shell/FEEDBACK_DIALOG_OPEN';
export const FEEDBACK_DIALOG_CLOSE = '@@shell/FEEDBACK_DIALOG_CLOSE';
export const NOTIFICATION_CLOSE = '@@shell/NOTIFICATION_CLOSE';
export const NOTIFICATION_OPEN = '@@shell/NOTIFICATION_OPEN';
