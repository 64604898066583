import { getTrackAlbumArtistsText, getTrackImage } from 'helpers/track';
import { toSeconds } from 'helpers/to-seconds';
import type { Track } from 'types';
import logger from 'utilities/logger';
import { captureException } from 'overrides/sentry';
import { getCurrentTime, seek, getPlaybackRate } from 'shared/web-player';

const supportsMediaSession = 'mediaSession' in navigator;

type Options = {
    onPlay: () => void;
    onPause: () => void;
    onPrev: (() => void) | null;
    onNext: (() => void) | null;
};

const MediaSession = {
    init({ onPlay, onPause, onPrev, onNext }: Options) {
        if (supportsMediaSession) {
            try {
                navigator.mediaSession.setActionHandler('play', onPlay);
                navigator.mediaSession.setActionHandler('pause', onPause);
                navigator.mediaSession.setActionHandler(
                    'previoustrack',
                    onPrev
                );
                navigator.mediaSession.setActionHandler('nexttrack', onNext);
                navigator.mediaSession.setActionHandler('seekto', details => {
                    seek(details.seekTime);
                });
            } catch (e) {
                captureException(e);
            }
        }
    },

    update(track: Track) {
        if (supportsMediaSession) {
            const metadata = this.createMediaMetadata(track);

            if (metadata) {
                try {
                    navigator.mediaSession.metadata = metadata;

                    if (navigator.mediaSession.setPositionState) {
                        navigator.mediaSession.setPositionState({
                            duration: toSeconds(track.duration),
                            position: getCurrentTime()
                        });
                    }
                } catch (e) {
                    captureException(e);
                }
            }
        }
    },

    createMediaMetadata(track: Track) {
        const { album, artist } = track;

        if (album) {
            const trackImageOptions = {
                album,
                artist
            };

            const data = {
                title: track.title,
                artist: getTrackAlbumArtistsText(track),
                album: album.title,
                artwork: [
                    {
                        src: getTrackImage(trackImageOptions, 'large'),
                        sizes: '500x500',
                        type: 'image/jpeg'
                    },

                    {
                        src: getTrackImage(trackImageOptions),
                        sizes: '300x300',
                        type: 'image/jpeg'
                    },
                    {
                        src: getTrackImage(trackImageOptions, 'thumb'),
                        sizes: '150x150',
                        type: 'image/jpeg'
                    }
                ]
            };

            return new MediaMetadata(data);
        }

        logger.warn(
            `createMediaMetadata:${track.title} - error creating metadata`
        );
    },

    onPlay(track: Track) {
        if (supportsMediaSession) {
            navigator.mediaSession.playbackState = 'playing';

            this.update(track);
        }
    },

    onPause() {
        if (supportsMediaSession) {
            navigator.mediaSession.playbackState = 'paused';
        }
    },

    onStop() {
        if (supportsMediaSession) {
            navigator.mediaSession.playbackState = 'none';
        }
    },

    onPosition({ position, duration }: { position: number; duration: number }) {
        if (supportsMediaSession && navigator.mediaSession?.setPositionState) {
            if (position <= duration) {
                navigator.mediaSession.setPositionState({
                    position,
                    duration,
                    playbackRate: getPlaybackRate()
                });
            } else {
                logger.warn(
                    `position:${position} cannot be greater than duration:${duration}`
                );
            }
        }
    }
};

export default MediaSession;
