import merge from 'lodash/merge';
import env from 'env';
import base from './default.json';
import dev from './dev.json';
import qa from './qa.json';
import int from './int.json';
import a from './a.json';
import staging from './staging.json';
import prod from './prod.json';

const PREFIX = 'REACT_APP_';

const { hostname, port } = globalThis.location;
let ENV = 'local';

let override = {};

const isAndroidMobile =
    'userAgentData' in navigator &&
    navigator.userAgentData?.mobile &&
    navigator.userAgentData?.platform === 'Android';

const isMobileConnection =
    'connection' in navigator &&
    ['wifi', 'cellular'].includes(navigator.connection?.type ?? '');

const isUsbDebugging = isAndroidMobile && isMobileConnection;

const isLocalhost = ['localhost', 'epoh.internal'].includes(hostname);

switch (hostname) {
    case 'dev.epoh.com.ng':
        ENV = 'dev';
        override = dev;
        break;
    case 'qa.epoh.com.ng':
        ENV = 'qa';
        override = qa;
        break;
    case 'int.epoh.com.ng':
        ENV = 'int';
        override = int;
        break;
    case 'a.epoh.com.ng':
        ENV = 'a';
        override = a;
        break;
    case 'staging.epoh.com.ng':
        ENV = 'staging';
        override = staging;
        break;
    case 'epoh.ng':
        ENV = 'prod';
        override = prod;
        break;
    default:
        if (!isLocalhost || isUsbDebugging) {
            override = dev;
        }

        if (port !== '3000' && !base.API_HOST) {
            base.API_HOST = 'api.epoh.internal';
        }
}

const envConfig = Object.fromEntries(
    Object.entries(env)
        .filter(([key]) => {
            return key.indexOf(PREFIX) === 0;
        })
        .map(([key, value]) => {
            return [key.replace(PREFIX, ''), value];
        })
);
const config = merge({}, base, envConfig, override, {
    ENV
});

export default Object.freeze(config) as typeof base;
