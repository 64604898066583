import type { Middleware } from 'redux';
import {
    LOAD,
    ON_PLAYER_READY,
    ON_PLAYLIST_ITEM,
    CART_ADD_ITEM,
    CART_CLEAR,
    MUSIC_CART_ADD_ITEM,
    MUSIC_CART_REMOVE_ITEM,
    MUSIC_CART_CLEAR
} from 'actions/types';
import type Actions from 'actions';
import { load } from 'actions';
import type { AppDispatch, RootState } from 'store';
import type {
    PlayerPlaylist,
    Cart,
    PlayerParams,
    MusicCart,
    TrackId
} from 'types';
import localStorage from 'shared/storage/localStorage';

type PlayerQueue = {
    playlist?: PlayerPlaylist;
    trackId?: TrackId;
    params: PlayerParams;
};

const QUEUE_VERSION = '3.5.6';
const storage: Middleware<{}, RootState, AppDispatch> =
    ({ dispatch, getState }) =>
    next =>
        function storage(action: Actions) {
            const result = next(action as any);

            switch (action.type) {
                case LOAD: {
                    const { playlist, trackId, params } = action.payload;

                    if (playlist) {
                        localStorage.set<PlayerQueue>(
                            'queue',
                            {
                                playlist,
                                trackId,
                                params
                            },
                            QUEUE_VERSION
                        );
                    }

                    break;
                }
                case ON_PLAYLIST_ITEM: {
                    const { trackId } = action.payload;
                    const queue = localStorage.get<PlayerQueue>(
                        'queue',
                        QUEUE_VERSION
                    );

                    if (trackId && queue) {
                        localStorage.set<PlayerQueue>(
                            'queue',
                            {
                                ...queue,
                                trackId
                            },
                            QUEUE_VERSION
                        );
                    }

                    break;
                }
                case ON_PLAYER_READY: {
                    const queue = localStorage.get<PlayerQueue>(
                        'queue',
                        QUEUE_VERSION
                    );

                    if (queue) {
                        const { playlist, trackId, params } = queue;

                        if (playlist) {
                            dispatch(
                                load({
                                    playlist,
                                    trackId,
                                    params
                                })
                            );
                        }
                    }

                    break;
                }
                case CART_ADD_ITEM: {
                    const { cart } = getState();

                    localStorage.set<Cart>('cart', cart);
                    break;
                }
                case CART_CLEAR: {
                    localStorage.remove('cart');
                    break;
                }
                case MUSIC_CART_ADD_ITEM:
                case MUSIC_CART_REMOVE_ITEM: {
                    const { musicCart } = getState();

                    localStorage.set<MusicCart>('music-cart', musicCart);
                    break;
                }
                case MUSIC_CART_CLEAR: {
                    localStorage.remove('music-cart');
                    break;
                }
                default:
            }

            return result;
        };

export default storage;
