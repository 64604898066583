// @ts-nocheck
import './pad-start';
import 'scheduler-polyfill';

if (!window.crypto) {
    window.crypto = window.msCrypto;
}

window.requestIdleCallback =
    window.requestIdleCallback ||
    function (handler) {
        const startTime = Date.now();

        return setTimeout(() => {
            handler({
                didTimeout: false,
                timeRemaining: () => {
                    return Math.max(0, 50.0 - (Date.now() - startTime));
                }
            });
        }, 1);
    };

window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function (id) {
        clearTimeout(id);
    };
