import type { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type Props = {
    heading?: string;
    body?: string;
    children?: ReactNode;
};

const Empty = ({
    heading = 'Nothing here',
    body = 'Come back later',
    children
}: Props) => (
    <Box
        className='flex flex-grow align-center'
        style={{ whiteSpace: 'pre-line' }}
        textAlign='center'
    >
        <Typography variant='h6'>{heading}</Typography>
        <>
            <Typography variant='body2' align='center'>
                {body}
            </Typography>
            {children}
        </>
    </Box>
);

export default Empty;
