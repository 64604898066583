import { useState, useEffect } from 'react';
import { isMobile } from 'helpers/is-mobile';
import { isTablet } from 'helpers/is-tablet';
import { isDesktop } from 'helpers/is-desktop';

const events = ['resize', 'orientationchange'];

const getLayout = () => ({
    isMobile: isMobile(),
    isTablet: isTablet(),
    isDesktop: isDesktop()
});

export const useLayout = () => {
    const [layout, setLayout] = useState(getLayout());

    const onResize = () => {
        setLayout(getLayout());
    };

    useEffect(() => {
        events.forEach(event => {
            window.addEventListener(event, onResize);
        });

        return () => {
            events.forEach(event => {
                window.removeEventListener(event, onResize);
            });
        };
    }, []);

    return layout;
};
