import type { ReactNode } from 'react';
import type { AlertProps } from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ButtonLink from 'components/inputs/ButtonLink';

export type Props = {
    severity?: AlertProps['severity'];
    action?: {
        to: string;
        text: string;
    };
    show: boolean;
    children: ReactNode;
    onClose?: AlertProps['onClose'];
    icon?: AlertProps['icon'];
    title?: AlertProps['title'];
};

const Alert = ({
    severity = 'info',
    action,
    show,
    children,
    onClose,
    icon,
    title
}: Props) => {
    if (!show) {
        return null;
    }

    return (
        <MuiAlert
            onClose={onClose}
            sx={{ mb: '5px' }}
            severity={severity}
            action={
                action ? (
                    <ButtonLink color='primary' to={action.to}>
                        {action.text}
                    </ButtonLink>
                ) : undefined
            }
            icon={icon}
        >
            {title && <AlertTitle>{title}</AlertTitle>}
            {children}
        </MuiAlert>
    );
};

export default Alert;
