import type { MouseEvent, SyntheticEvent, FunctionComponent } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Divider from '@mui/material/Divider';
import { DRAWER_WIDTH } from 'shared/constants/layout';
import { getAppName } from 'helpers/app';
import { useLayout } from 'hooks/use-layout';
import MenuList from './MenuList';
import Logo from './Logo';
import SocialLinks from './SocialLinks';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menu: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            minWidth: DRAWER_WIDTH,
            overflowY: 'auto',
            padding: 0
        },
        toolbar: {
            ...theme.mixins.toolbar
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: DRAWER_WIDTH,
                flexShrink: 0
            }
        },
        paper: {
            minWidth: DRAWER_WIDTH
        },
        logo: {
            padding: '10px 20px',
            backgroundColor: theme.palette.primary.main
        }
    })
);

type Props = {
    isLoggedIn: boolean;
    isOpen: boolean;
    onClick: (e: MouseEvent<HTMLDivElement>) => void;
    onClose: (e: SyntheticEvent<HTMLDivElement>) => void;
    onOpen: (e: SyntheticEvent<HTMLDivElement>) => void;
};

const Drawer: FunctionComponent<React.PropsWithChildren<Props>> = ({
    isLoggedIn,
    isOpen,
    onClick,
    onClose,
    onOpen
}) => {
    const { isDesktop } = useLayout();
    const classes = useStyles();

    return (
        <SwipeableDrawer
            id='drawer'
            onClick={onClick}
            onClose={onClose}
            onOpen={onOpen}
            open={isDesktop || isOpen}
            variant={isDesktop ? 'permanent' : 'temporary'}
            classes={{
                paper: classes.paper
            }}
        >
            {!isDesktop ? (
                <div className={classes.logo}>
                    <Logo className='left' />
                </div>
            ) : (
                <div className={classes.toolbar} />
            )}
            <Divider />
            <MenuList
                id='menu'
                classPrefix='menu'
                className={classes.menu}
                isLoggedIn={isLoggedIn}
            />
            <Divider />
            <SocialLinks />
            <div
                className='flex flex-grow copyright'
                style={{
                    justifyContent: 'flex-end',
                    padding: '0 15px'
                }}
            >
                <Typography>
                    {`© ${new Date().getFullYear()} ${getAppName()}`}
                </Typography>
            </div>
        </SwipeableDrawer>
    );
};

export default Drawer;
