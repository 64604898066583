const routes = {
    ABOUT: '/about',
    ACTIVATION: '/activate/:email/:key',
    ALBUM: '/artists/:artistSlug/albums/:albumSlug',
    ALBUMS: '/albums',
    ARTIST_SALES: '/artist/sales',
    ARTIST_SONG_MANAGEMENT: '/artist/song-management',
    ARTIST_VERIFICATION: '/artist/verification',
    ARTIST: '/artists/:artistSlug',
    ARTISTS: '/artists',
    ARTIST_CONTENT_UPLOAD: '/content/upload',
    CART_PAYMENT: '/cart/payment',
    CART: '/cart',
    DASHBOARD_MUSIC: '/dashboard/music',
    DASHBOARD_USERS: '/dashboard/users',
    DASHBOARD_CONTENTS: '/dashboard/contents',
    DASHBOARD_PUSH_NOTIFICATIONS: '/dashboard/push-notifications',
    DASHBOARD: '/dashboard',
    DISCOVER: '/discover',
    DJ_PLAYLISTS: '/djs/:djSlug/playlists/:playlistId',
    DJ: '/djs/:djSlug',
    DJ_BOOKINGS: '/dj/bookings',
    DJ_BOOKING: '/dj/bookings/:bookingId',
    DJ_BOOKING_PAYOUT: '/djs/bookings/:bookingId/payout',
    DJS: '/djs',
    FAQ: '/faq',
    FORGOT_PASSWORD: '/forgot-password',
    GENRE: '/genres/:genreSlug',
    GENRES: '/genres',
    HOME: '/',
    LIBRARY_PLAYLISTS: '/library/playlists/:playlistId',
    LIBRARY: '/library',
    LOGIN: '/login',
    MUSIC_MIX: '/music-mix',
    ORGANIZER_SHOW_PAYOUT: '/organizer/shows/:showId/payout',
    ORGANIZER_SHOW_CREATE: '/organizer/shows/create',
    ORGANIZER_SHOW_MODIFY: '/organizer/shows/:showId',
    ORGANIZER_SHOW_PREVIEW: '/organizer/shows/:showId/preview',
    ORGANIZER_SHOW_TICKETS: '/organizer/shows/:showId/tickets',
    ORGANIZER_SHOW_COUPONS: '/organizer/shows/:showId/coupons',
    PUSH_NOTIFICATIONS: '/settings/push-notifications',
    PLAYLISTS: '/playlists/:playlistId',
    ORGANIZER_SHOWS: '/organizer/shows',
    QUEUE: '/queue',
    RECENTLY_ADDED: '/recently-added',
    RECENTS: '/recents',
    RECOMMENDED: '/recommended',
    RECORD_LABELS: '/record-labels',
    REGISTER: '/register',
    RESET_PASSWORD: '/reset-password/:id/:key',
    REQUEST_DJ_BOOKING: '/djs/:djSlug/booking',
    SONG: '/songs/:trackId',
    SETTINGS_PROFILE: '/settings/profile',
    SETTINGS: '/settings',
    SHOW_PAYMENT: '/shows/:showId/payment',
    SHOW: '/shows/:showId',
    SHOWS: '/shows',
    USER_BOOKINGS: '/settings/bookings',
    USER_BOOKING: '/settings/bookings/:bookingId',
    /**
     * used in [API] src\epoh\Lib\Helpers\StripeClient.php
     * please update if changed
     */
    STRIPE_ACCOUNT: '/shows/:showId/stripe-account',
    VERIFY_ACCOUNT: '/verify-otp/:email',
    BROWSE: '/browse'
} as const;

export type RouteKeys = keyof typeof routes;
export type RouteValues = (typeof routes)[RouteKeys];
export default routes;
