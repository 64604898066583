import { FORM_URLENCODED } from 'enums/content-type';
import type { FetchPayload } from 'types/fetch';
import URIS from 'queries/uris.json';
import type { RegisterUserData } from 'types/store';
import { FETCH } from '../types';
import { getUsersParams, getClientAuthorization } from './helpers';

export const fetchToken = ({
    options: { data } = {},
    loginType,
    method = 'post'
}: FetchPayload<Record<string, any>>) => {
    const payload = {
        method,
        onSuccess: fetchUser,

        trigger: true,
        url: URIS.token
    };

    const credentialData =
        loginType === 'Auto'
            ? {
                  grantType: 'refresh_token',
                  responseType: 'token'
              }
            : {
                  grantType: 'password',
                  responseType: 'token'
              };

    switch (method) {
        case 'post':
        default:
            return {
                payload: {
                    loginType,
                    ...payload,
                    resourceName: 'TOKEN',
                    options: {
                        data: {
                            ...credentialData,
                            ...data
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': FORM_URLENCODED,
                            Authorization: getClientAuthorization()
                        }
                    }
                },
                type: FETCH
            } as const;
    }
};

export const fetchRefreshToken = ({
    options: { data } = {},
    method = 'post'
}: FetchPayload<Record<string, any>>) => {
    const payload = {
        method,
        url: URIS.token
    };

    switch (method) {
        case 'post':
        default:
            return {
                payload: {
                    ...payload,
                    resourceName: 'TOKEN',
                    options: {
                        data: {
                            ...data,
                            grantType: 'refresh_token'
                        },
                        withCredentials: true,
                        headers: {
                            Authorization: getClientAuthorization()
                        }
                    }
                },
                type: FETCH
            } as const;
    }
};

export const fetchUser = ({
    options: { data } = {},
    method = 'get',
    meta
}: FetchPayload<RegisterUserData>) => {
    const payload = {
        method,
        meta
    };

    switch (method) {
        case 'post':
            return {
                payload: {
                    ...payload,
                    resourceName: 'USERS',
                    url: URIS.users.index,
                    options: {
                        data
                    }
                },
                type: FETCH
            } as const;
        default:
            return {
                payload: {
                    ...payload,
                    resourceName: 'USERS',
                    url: URIS.users.current,
                    options: {
                        data,
                        params: getUsersParams()
                    }
                },
                type: FETCH
            } as const;
    }
};

export const fetchLogout = () =>
    ({
        payload: {
            url: URIS.users.logout,
            method: 'post',
            resourceName: 'LOGOUT'
        },
        type: FETCH
    } as const);
