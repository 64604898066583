import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import axios from 'overrides/axios';
import type { Artist, Album, Playlist, Show, FirebaseHome } from 'types';
import type { FetchResponseError } from 'types/fetch';
import { getDatabaseValue } from 'overrides/firebase.database';
import URIS from './uris.json';

type HomeResponse = {
    popularArtists: Artist[];
    discover: Album[];
    newReleases: Album[];
    playlists: Playlist[];
    popular: Album[];
    promoted: Album[];
    recentlyAdded: Album[];
    shows: Show[];
    alternative: Album[];
    throwback: Album[];
};

export type HomeResponseKeys = keyof HomeResponse;

export const pagesQueryKeys = {
    HOME: 'pages.home'
};

const getPagesHome = async (): Promise<FirebaseHome> => {
    const [response, firebaseData] = await Promise.all([
        axios.get<HomeResponse>(URIS.pages.home),
        getDatabaseValue<FirebaseHome>('/home')
    ]);

    // @ts-ignore
    return firebaseData
        .map(item => {
            if (!item.data && response.data?.[item.id as HomeResponseKeys]) {
                return {
                    ...item,
                    data: response.data[item.id as HomeResponseKeys]
                };
            }

            return item;
        })
        .filter(item => item.data && item.data.length > 0);
};

export const homeQuery = {
    queryKey: [pagesQueryKeys.HOME],
    queryFn: getPagesHome,
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60 * 24
};

export const usePagesHomeQuery = (
    options?: UseQueryOptions<FirebaseHome, FetchResponseError, FirebaseHome>
) => {
    return useQuery<FirebaseHome, FetchResponseError, FirebaseHome>({
        ...options,
        ...homeQuery
    });
};
