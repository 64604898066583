import type { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { DRAWER_WIDTH } from 'shared/constants/layout';

const styles = (theme: Theme) => {
    const zIndex = theme.zIndex.drawer + 1;

    return createStyles({
        root: {
            zIndex: 1,
            position: 'relative'
        },
        appBar: {
            zIndex,
            backgroundColor: theme.palette.background.default
        },
        appBarSearch: {
            position: 'absolute',
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            minWidth: DRAWER_WIDTH,
            backgroundColor: theme.palette.background.default,
            zIndex
        },
        muiIconButtonLeft: {
            marginRight: 6
        },
        hide: {
            display: 'none'
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            })
        },
        'content-left': {
            marginLeft: -DRAWER_WIDTH
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        'contentShift-left': {
            marginLeft: 0
        },
        toolbar: {
            height: 56
        },
        loginIcon: {
            marginRight: 15,
            marginTop: 15
        },
        loginText: {
            float: 'left',
            margin: 15
        },
        menuButton: {
            color: theme.palette.background.default
        }
    });
};

export default styles;
