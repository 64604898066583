import type { ReactNode } from 'react';
import Empty from './Empty';

type Props = {
    children?: ReactNode;
    message?: string;
    heading?: string;
};

const ErrorMessage = ({
    children,
    message = 'Something don happen, try again. No vex',
    heading = 'Yawa!'
}: Props) => (
    <Empty heading={heading} body={message}>
        {children}
    </Empty>
);

export default ErrorMessage;
