let _player: cast.framework.RemotePlayer | null = null;
let _controller: cast.framework.RemotePlayerController | null = null;
let _context: cast.framework.CastContext | null = null;

const getPlayer = () => {
    if (!_player) {
        _player = new cast.framework.RemotePlayer();
    }

    return _player;
};

export const getController = () => {
    if (!_controller) {
        _controller = new cast.framework.RemotePlayerController(getPlayer());
    }

    return _controller;
};

export const getContext = () => {
    if (!_context) {
        _context = cast.framework.CastContext.getInstance();
    }

    return _context;
};

export const seek = (seekTime: number) => {
    const player = getPlayer();
    const controller = getController();

    player.currentTime = seekTime;
    controller.seek();
};

export const onTimeUpdate = (
    callback: (e: cast.framework.RemotePlayerChangedEvent<any>) => void
) => {
    const { CURRENT_TIME_CHANGED } = cast.framework.RemotePlayerEventType;
    const controller = getController();

    controller.addEventListener(CURRENT_TIME_CHANGED, callback);

    return () => {
        controller.removeEventListener(CURRENT_TIME_CHANGED, callback);
    };
};

export const onVolumeChange = (
    callback: (e: cast.framework.RemotePlayerChangedEvent<any>) => void
) => {
    const { VOLUME_LEVEL_CHANGED } = cast.framework.RemotePlayerEventType;
    const controller = getController();

    controller.addEventListener(VOLUME_LEVEL_CHANGED, callback);

    return () => {
        controller.removeEventListener(VOLUME_LEVEL_CHANGED, callback);
    };
};

export const stop = () => {
    const controller = getController();

    controller.stop();
};

export const getCurrentTime = () => {
    const player = getPlayer();

    return typeof player.currentTime === 'number' ? player.currentTime : 0;
};

export const getDuration = () => {
    const player = getPlayer();

    return player.duration;
};

export const setVolume = (volume: number) => {
    const player = getPlayer();
    const controller = getController();

    player.volumeLevel = volume / 100;

    controller.setVolumeLevel();
};

export const getIsConnected = () => {
    const player = getPlayer();

    return player.isConnected;
};

export default getPlayer;
