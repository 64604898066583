import { EventEmitter } from 'events';

const events = new EventEmitter();

export const queue = (event: string, delay = 0) => {
    scheduler.postTask(
        () => {
            events.emit(event);
        },
        { delay }
    );
};

export default events;
