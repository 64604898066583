import type { ShellState } from 'types/store';
import type Actions from 'actions';
import type { Snackbar } from 'types';
import {
    ALERT_CLOSE,
    ALERT_OPEN,
    BACK_BUTTON_HIDE,
    BACK_BUTTON_SHOW,
    FETCH_BEGIN,
    FETCH_END,
    FETCH_FAIL,
    FETCH_TOKEN,
    FETCH_TOKEN_ERROR,
    FETCH_USERS_ERROR,
    FETCH_USERS_SUCCESS,
    LOADING_START,
    LOADING_STOP,
    NOTIFICATION_CLOSE,
    NOTIFICATION_OPEN,
    ON_CONNECTION,
    ON_PAGE_READY,
    ON_PUSH_SUBSCRIPTION,
    SNACKBAR_CLOSE,
    SNACKBAR_OPEN,
    TAB_CHANGE,
    FEEDBACK_DIALOG_OPEN,
    FEEDBACK_DIALOG_CLOSE
} from '../actions/types';
import initialState from '../state';

const shell = (state = initialState, action: Actions): ShellState => {
    switch (action.type) {
        case FETCH_TOKEN:
            return {
                ...state,
                ...{
                    isLoading: false,
                    isLoggingIn: true
                }
            };
        case FETCH_USERS_ERROR:
        case FETCH_TOKEN_ERROR:
            return {
                ...state,
                ...{
                    isLoggingIn: false
                }
            };
        case FETCH_USERS_SUCCESS: {
            const {
                meta: {
                    // @ts-ignore
                    originalAction: {
                        payload: { method }
                    }
                }
            } = action.payload;

            return method === 'get'
                ? {
                      ...state,
                      ...{
                          isLoading: false,
                          isLoggingIn: false
                      }
                  }
                : state;
        }
        case FETCH_BEGIN:
            return {
                ...state,
                ...{
                    isFetching: true
                }
            };
        case FETCH_END:
        case FETCH_FAIL:
            return {
                ...state,
                ...{
                    isFetching: false
                }
            };
        case LOADING_START:
            return {
                ...state,
                ...{
                    isLoading: true
                }
            };
        case LOADING_STOP:
            return {
                ...state,
                ...{
                    isLoading: false
                }
            };
        case SNACKBAR_OPEN: {
            const { message, options = {} } = action.payload;

            const snackbar = {
                isOpen: true,
                message,
                options
            };
            const shouldQueue =
                !!state.snackbar.message && state.snackbar.options.persist;
            const pendingSnackbars = state.pendingSnackbars;

            if (shouldQueue) {
                pendingSnackbars.push(state.snackbar);
            }

            return {
                ...state,
                ...{
                    snackbar,
                    pendingSnackbars: pendingSnackbars
                }
            };
        }
        case SNACKBAR_CLOSE: {
            const nextSnackbar: Snackbar = {
                ...state.pendingSnackbars.pop(),
                isOpen: true
            } as Snackbar;

            const inQueue = Boolean(nextSnackbar.message);

            return {
                ...state,
                ...{
                    snackbar: inQueue ? nextSnackbar : initialState.snackbar
                }
            };
        }
        case ALERT_OPEN: {
            const { message, options } = action.payload;

            return {
                ...state,
                ...{
                    alert: {
                        ...state.alert,
                        show: true,
                        message,
                        options
                    }
                }
            };
        }
        case ALERT_CLOSE: {
            return {
                ...state,
                ...{
                    alert: {
                        ...state.alert,
                        show: false
                    }
                }
            };
        }
        case TAB_CHANGE: {
            const { index } = action.payload;

            return {
                ...state,
                ...{
                    tabIndex: index
                }
            };
        }
        case BACK_BUTTON_SHOW:
            return {
                ...state,
                ...{
                    showBackButton: true
                }
            };
        case BACK_BUTTON_HIDE:
            return {
                ...state,
                ...{
                    showBackButton: false
                }
            };
        case ON_PAGE_READY:
            return {
                ...state,
                ...{
                    isPageReady: true
                }
            };
        case ON_CONNECTION: {
            const { type } = action.payload;

            return {
                ...state,
                ...{
                    connection: type
                }
            };
        }
        case ON_PUSH_SUBSCRIPTION: {
            const { isPushEnabled } = action.payload;

            return {
                ...state,
                ...{
                    isPushEnabled
                }
            };
        }
        case FEEDBACK_DIALOG_OPEN: {
            const { scope } = action.payload;

            return {
                ...state,
                ...{
                    feedback: {
                        ...state.feedback,
                        show: true,
                        scope
                    }
                }
            };
        }
        case FEEDBACK_DIALOG_CLOSE: {
            return {
                ...state,
                ...{
                    feedback: {
                        ...state.feedback,
                        show: false
                    }
                }
            };
        }
        case NOTIFICATION_OPEN: {
            return {
                ...state,
                ...{
                    isNotificationOpen: true
                }
            };
        }
        case NOTIFICATION_CLOSE: {
            return {
                ...state,
                ...{
                    isNotificationOpen: initialState.isNotificationOpen
                }
            };
        }
        default:
            return state;
    }
};

export default shell;
