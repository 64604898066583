import type { Middleware } from 'redux';
import { GRANT_TYPE_FEDERATED } from 'enums/grant-type';
import type { AxiosResponseWithData } from 'overrides/axios';
import axios from 'overrides/axios';
import { FETCH, FETCH_USERS_SUCCESS } from 'actions/types';
import {
    fetchToken,
    loadingStart,
    loadingStop,
    snackbarOpen,
    fetchBegin,
    fetchEnd,
    fetchFail
} from 'actions';
import type { FetchAction, FetchResponseSuccess } from 'types/fetch';
import type { AppDispatch, RootState } from 'store';

const api: Middleware<{}, RootState, AppDispatch> = ({
    dispatch,
    getState
}) => {
    axios.interceptors.request.use(request => {
        // Do something before request is sent
        dispatch(fetchBegin(request.data!));

        return request;
    });

    axios.interceptors.response.use(
        (response: AxiosResponseWithData<FetchResponseSuccess<unknown>>) => {
            dispatch(fetchEnd(response.data));

            return response;
        },
        error => {
            dispatch(fetchFail(error));

            return Promise.reject(error);
        }
    );

    return next => async action => {
        const result = next(action);

        switch (action.type) {
            case FETCH: {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve, reject) => {
                    const {
                        errorMessage,
                        method = 'get',
                        onError,
                        onSuccess,
                        options = {},
                        parse = true,
                        resourceName,
                        showMessageOnError,
                        showSpinner,
                        silent,
                        successMessage,
                        trigger,
                        url
                    } = (action as FetchAction).payload;

                    const triggerActions =
                        (trigger || !silent) && Boolean(resourceName);
                    const isTokenFetch = resourceName === 'TOKEN';

                    const meta = {
                        originalAction: action
                    };

                    if (showSpinner) {
                        dispatch(loadingStart());
                    }

                    if (triggerActions) {
                        dispatch({
                            type: `FETCH_${resourceName}`,
                            payload: {
                                ...action.payload
                            }
                        });
                    }

                    try {
                        const response = await axios(url, {
                            method,
                            ...options
                        });
                        const { code, ...extraData } = response as any;
                        const data = response.data;

                        const payload = {
                            ...(!parse || isTokenFetch ? extraData : {}),
                            data,
                            code,
                            meta
                        };

                        if (!(triggerActions && onSuccess)) {
                            resolve(response);
                        }

                        if (triggerActions) {
                            dispatch({
                                type: `FETCH_${resourceName}_SUCCESS`,
                                payload
                            });
                        }

                        // onSuccess callback handling
                        if (onSuccess) {
                            resolve(dispatch(onSuccess(payload)));
                        }

                        // snackbar message
                        if (successMessage) {
                            dispatch(snackbarOpen(successMessage));
                        }
                    } catch (error) {
                        const payload = {
                            error,
                            method,
                            meta
                        };

                        if (triggerActions) {
                            dispatch({
                                type: `FETCH_${resourceName}_ERROR`,
                                payload
                            });
                        }

                        // onError handling
                        if (onError) {
                            dispatch(onError(error));
                        }

                        // snackbar error display
                        if (errorMessage) {
                            dispatch(snackbarOpen(errorMessage));
                        } else if (showMessageOnError) {
                            dispatch(snackbarOpen((error as Error).message));
                        }

                        if (!(triggerActions && onError)) {
                            resolve(payload);
                        }
                    } finally {
                        if (showSpinner) {
                            dispatch(loadingStop());
                        }
                    }
                });
            }
            case FETCH_USERS_SUCCESS: {
                const {
                    meta: {
                        originalAction: {
                            payload: {
                                options: { data = {} },
                                method
                            }
                        }
                    }
                } = action.payload;
                const { accessToken, providerId, email } = data;
                const isSocialReg = Boolean(providerId);

                if (isSocialReg && method === 'post') {
                    const data = {
                        ...{
                            grantType: GRANT_TYPE_FEDERATED
                        },
                        provider: providerId,
                        identifier: accessToken,
                        email
                    };

                    dispatch(
                        fetchToken({
                            method: 'post',
                            options: { data },
                            loginType: 'Social'
                        })
                    );
                }

                break;
            }
            default:
        }

        return result;
    };
};

export default api;
