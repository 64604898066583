import { useMemo } from 'react';
import type { MouseEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { getPlayer } from 'selectors';

type Props = {
    disconnectedColor?: string;
    display?: string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

type ChromecastButtonStyle = Partial<{
    display: string;
    '--disconnected-color': string;
}>;

const CastButton = ({ display, disconnectedColor, onClick }: Props) => {
    const { isCastAvailable, device, deviceName } = useSelector(getPlayer);
    const title =
        device === 'remote'
            ? `Casting to ${deviceName}`
            : 'Play on Cast device';

    const style = useMemo(() => {
        const style: ChromecastButtonStyle = {
            display
        };

        if (disconnectedColor) {
            style['--disconnected-color'] = disconnectedColor;
        }

        return style;
    }, [disconnectedColor, display]);

    return isCastAvailable ? (
        <IconButton
            onClick={onClick}
            aria-label={title}
            style={{
                pointerEvents: 'initial'
            }}
            title={title}
            size='large'
        >
            <google-cast-launcher style={style} />
        </IconButton>
    ) : null;
};

export default CastButton;
