import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ROUTES from 'shared/routes';
import TrackedButton from 'components/inputs/TrackedButton';
import TrackedCloseButton from 'components/inputs/TrackedCloseButton';
import PrivacyLink from '../inputs/PrivacyLink';

const points = [
    {
        index: 1,
        icon: <CloudUploadRoundedIcon fontSize='small' />,
        header: 'Upload Your Albums And EPs',
        text: 'Upload your albums or EPs by yourself'
    },
    {
        index: 2,
        icon: <AttachMoneyIcon fontSize='small' />,
        header: 'Decide How To Share',
        text: 'Put your work up for sale or Download'
    },
    {
        index: 3,
        icon: <AnnouncementIcon fontSize='small' />,
        header: 'Get Feedback On Upload',
        text: 'Get Feedback of each upload in realtime.'
    }
];

const ArtistUploadNotification = () => {
    return (
        <Container maxWidth='sm' className='notification'>
            <Box
                pl={3}
                pt={3}
                style={{
                    background:
                        'linear-gradient(rgba(0, 0, 0, 0.358) 85%, rgb(0, 0, 0)), url(/static/images/artist-upload-banner.png) center center no-repeat'
                }}
            >
                <TrackedCloseButton name={'artist-upload'} />
                <Box pl={5} pt={6} id='notification-title'>
                    <Typography variant='h6'>Introducing</Typography>
                    <Typography fontWeight={'bold'} variant='h4'>
                        Artist Upload
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: '#000000'
                }}
            >
                <DialogContent>
                    <List>
                        {points.map(point => (
                            <ListItem key={point.index}>
                                <ListItemAvatar>
                                    <Avatar>{point.icon}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        textTransform: 'none'
                                    }}
                                    primaryTypographyProps={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        textTransform: 'none'
                                    }}
                                    secondaryTypographyProps={{
                                        fontSize: 10
                                    }}
                                    primary={point.header}
                                    secondary={point.text}
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <div className='notification-footer'>
                    <div>
                        <TrackedButton
                            event={{
                                event: 'notification:dialog:activity',
                                data: {
                                    name: 'artist-upload',
                                    type: 'click'
                                }
                            }}
                            isClose
                            to={ROUTES.ARTIST_CONTENT_UPLOAD}
                        >
                            Continue
                        </TrackedButton>
                    </div>
                    <PrivacyLink />
                </div>
            </Box>
        </Container>
    );
};

export default ArtistUploadNotification;
