import type {
    CustomParams,
    CustomEventName,
    EventNameString,
    AnalyticsCallOptions,
    Analytics
} from 'firebase/analytics';
import {
    getAnalytics,
    setUserId,
    setUserProperties,
    logEvent,
    isSupported
} from 'firebase/analytics';
import app from 'overrides/firebase';
import logger from 'utilities/logger';

export const getIsSupported = isSupported();

const getFirebaseAnalytics = () => {
    return new Promise<Analytics>((resolve, reject) => {
        getIsSupported.then(supported => {
            if (supported) {
                resolve(getAnalytics(app));
            } else {
                reject(new Error('firebase/analytics is not supported'));
            }
        });
    });
};

export const setAnalyticsUserProperties = (properties: CustomParams) => {
    getFirebaseAnalytics()
        .then(analytics => {
            setUserProperties(analytics, properties);
        })
        .catch(logger.error);
};

export const setAnalyticsUserProperty = (key: string, value: unknown) => {
    getFirebaseAnalytics()
        .then(analytics => {
            setUserProperties(analytics, { [key]: value });
        })
        .catch(logger.error);
};

export const logAnalyticsCustomEvent = <T extends string>(
    eventName: CustomEventName<T>,
    eventParams?: {
        [key: string]: any;
    },
    options?: AnalyticsCallOptions
) => {
    scheduler.postTask(
        () => {
            getFirebaseAnalytics()
                .then(analytics => {
                    logEvent(analytics, eventName, eventParams, options);
                })
                .catch(logger.error);
        },
        {
            priority: 'background'
        }
    );
};

export const logAnalyticsEvent = (
    eventName: EventNameString,
    eventParams?: {
        [key: string]: any;
    },
    options?: AnalyticsCallOptions
) => {
    scheduler.postTask(
        () => {
            getFirebaseAnalytics()
                .then(analytics => {
                    return logEvent<string>(
                        analytics,
                        eventName,
                        eventParams,
                        options
                    );
                })
                .catch(logger.error);
        },
        {
            priority: 'background'
        }
    );
};

export const setAnalyticsUserId = (id: string) => {
    getFirebaseAnalytics()
        .then(analytics => {
            setUserId(analytics, id);
        })
        .catch(logger.error);
};

export default app;
