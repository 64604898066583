import getByKey from 'lodash/get';
import localStorage from 'shared/storage/localStorage';
import type { DevicePreferencesPaths } from 'types';
import { getPreferencesKey, getPreferencesVersion } from 'helpers/preferences';
import store from 'store';

export const getDevicePreferenceByKey = <T>(
    path: DevicePreferencesPaths,
    defaultValue: T
) => {
    const { user } = store.getState();
    const key = getPreferencesKey(user?.id);
    const version = getPreferencesVersion();
    const preferences = localStorage.get<T>(key, version);

    return getByKey(preferences, path, defaultValue) as T;
};
