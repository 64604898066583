import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useEventsMutation } from 'queries/global';
import type { AppDispatch } from 'store';
import { notificationClose } from 'actions';

type Props = {
    name: string;
};

const TrackedCloseButton = ({ name }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { mutate } = useEventsMutation();

    const onClick = () => {
        dispatch(notificationClose());

        mutate({
            event: 'notification:dialog:activity',
            data: {
                name,
                type: 'close'
            }
        });
    };

    return (
        <IconButton
            size='small'
            aria-label='close'
            onClick={onClick}
            data-test-id='close-notification'
            sx={{
                backgroundColor: '#ffff',
                color: '#000'
            }}
        >
            <CloseIcon fontSize='small' />
        </IconButton>
    );
};

export default TrackedCloseButton;
