import {
    createRoutesFromElements,
    createBrowserRouter,
    Route,
    RouterProvider,
    Outlet,
    useRouteError
} from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PageNotFound from 'pages/errors/PageNotFound';
import { ThemeContextProvider } from 'contexts/theme';
import type { PageRouteKeys } from 'shared/page-routes';
import PageRoutes from 'shared/page-routes';
import Restricted from 'components/Restricted';
import Shell from 'components/shell/Shell';
import ErrorMessage from 'components/ErrorMessage';
import queryClient from 'shared/query-client';
import { isDevEnv, isTestEnv } from 'helpers/env';

const routeKeys = Object.keys(PageRoutes) as PageRouteKeys[];

const ErrorBoundary = () => {
    const error = useRouteError() as Error;

    return <ErrorMessage message={error?.message} />;
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path='/'
            element={
                <Shell>
                    <Outlet />
                </Shell>
            }
            errorElement={<ErrorBoundary />}
        >
            {routeKeys.map(path => {
                if (!PageRoutes[path]) {
                    throw new Error(`Page route ${path} is not defined`);
                }

                const {
                    component: Component,
                    access,
                    key = path,
                    loader,
                    ...rest
                } = PageRoutes[path];

                const dataLoader = isTestEnv()
                    ? undefined
                    : loader?.(queryClient);

                if (access) {
                    return (
                        <Route
                            key={key}
                            path={path}
                            element={
                                <Restricted {...rest} access={access}>
                                    <Component />
                                </Restricted>
                            }
                            loader={dataLoader}
                            errorElement={<ErrorBoundary />}
                        />
                    );
                }

                return (
                    <Route
                        key={key}
                        path={path}
                        element={<Component />}
                        loader={dataLoader}
                        errorElement={<ErrorBoundary />}
                    />
                );
            })}
            <Route path='*' element={<PageNotFound />} />
        </Route>
    )
);

const App = () => {
    return (
        <>
            <ThemeContextProvider>
                <RouterProvider router={router} />
            </ThemeContextProvider>
            {isDevEnv() && (
                <ReactQueryDevtools
                    initialIsOpen={false}
                    toggleButtonProps={{
                        style: {
                            bottom: 85,
                            right: 30,
                            height: 36,
                            left: 'initial'
                        }
                    }}
                />
            )}
        </>
    );
};

export default App;
