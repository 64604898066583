import { lazy } from 'react';

export default lazy(
    () => import(/* webpackChunkName: "organizer-shows" */ './Shows')
);

const Tickets = lazy(
    () => import(/* webpackChunkName: "organizer-show-tickets" */ './tickets')
);

const Coupons = lazy(
    () => import(/* webpackChunkName: "organizer-show-coupons" */ './coupons')
);

export { Tickets, Coupons };
