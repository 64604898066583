import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import env from 'env';
import logger from 'utilities/logger';
import isSupported from './is-supported';

const { REACT_APP_VERSION } = env;
const VERSION = REACT_APP_VERSION || '1';

const storage = {
    enabled: isSupported('localStorage'),
    storage: isSupported('localStorage') ? localStorage : null,

    set<T>(key: string, value: T, version: string = VERSION) {
        if (this.enabled) {
            const data = {
                value,
                VERSION: version
            };

            return this.storage!.setItem(key, this.stringify(data));
        }
    },

    get<T>(key: string, version: string = VERSION) {
        if (this.enabled) {
            const rawData = this.storage!.getItem(key);

            if (rawData) {
                const data = this.parse(rawData);

                if (data && data.VERSION === version) {
                    return data.value as T;
                }
            }
        }
    },

    stringify(value: any) {
        if (isObject(value)) {
            try {
                return JSON.stringify(value);
            } catch (e) {
                logger.warn(e);
            }
        } else {
            logger.warn(`cannot stringify ${typeof value}`);
        }

        return value;
    },

    parse(value: string) {
        if (isString(value)) {
            try {
                return JSON.parse(value);
            } catch (e) {
                logger.warn(e);

                return value;
            }
        } else {
            logger.warn(`cannot parse ${typeof value}`);
        }
    },

    remove(value: any) {
        if (this.enabled) {
            return this.storage!.removeItem(value);
        }
    }
};

export default storage;
