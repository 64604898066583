const clientId = 1;
const clientSecret = 'abc123';

export const getUsersParams = () => ({
    fields: [
        'artist.contract',
        'bank',
        'commission',
        'dj.performances',
        'dj.service.locations',
        'dj.service.equipments',
        'geo_ip',
        'identities',
        'profile.country',
        'profile.state',
        'promoter.bank',
        'promoter.paypal',
        'promoter.stripe',
        'verification'
    ].join()
});

export const getClientAuthorization = () =>
    `Basic ${btoa(`${clientId}:${clientSecret}`)}`;
