if (!globalThis?.process) {
    globalThis.process = {
        env: {} as NodeJS.ProcessEnv
    } as NodeJS.Process;
}

const env = {
    NODE_ENV: process.env.NODE_ENV,
    PUBLIC_URL: process.env.PUBLIC_URL,
    REACT_APP_CONTENT_HOST: process.env.REACT_APP_CONTENT_HOST,
    REACT_APP_DESCRIPTION: process.env.REACT_APP_DESCRIPTION,
    REACT_APP_FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
    REACT_APP_GA4_TRACKING_ID: process.env.REACT_APP_GA4_TRACKING_ID,
    REACT_APP_GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID,
    REACT_APP_GIT_HEAD: process.env.REACT_APP_GIT_HEAD,
    REACT_APP_KEYWORDS: process.env.REACT_APP_KEYWORDS,
    REACT_APP_NAME: process.env.REACT_APP_NAME,
    REACT_APP_PACKAGE_NAME: process.env.REACT_APP_PACKAGE_NAME,
    REACT_APP_POSTHOG_API_KEY: process.env.REACT_APP_POSTHOG_API_KEY,
    REACT_APP_STATIC_HOST: process.env.REACT_APP_STATIC_HOST,
    REACT_APP_TITLE: process.env.REACT_APP_TITLE,
    REACT_APP_VERSION: process.env.REACT_APP_VERSION,
    REACT_APP_WORK_OFFLINE: process.env.REACT_APP_WORK_OFFLINE,
    REACT_APP_BUILD_REASON: process.env.REACT_APP_BUILD_REASON,
    REACT_APP_BUILD_SOURCEBRANCH: process.env.REACT_APP_BUILD_SOURCEBRANCH,
    REACT_APP_BUILD_SOURCEVERSIONAUTHOR:
        process.env.REACT_APP_BUILD_SOURCEVERSIONAUTHOR,
    REACT_APP_PULLREQUEST_SOURCEBRANCH:
        process.env.REACT_APP_PULLREQUEST_SOURCEBRANCH,
    REACT_APP_BUILD_NUM: process.env.REACT_APP_BUILD_NUM,
    REACT_APP_PULLREQUEST_TARGETBRANCH:
        process.env.REACT_APP_PULLREQUEST_TARGETBRANCH,
    REACT_APP_BUILD_TIME: process.env.REACT_APP_BUILD_TIME,
    JEST_WORKER_ID: process.env.JEST_WORKER_ID
};

export default env;
