import type { Dispatch, SetStateAction, ReactNode } from 'react';
import {
    useCallback,
    createContext,
    useState,
    useMemo,
    Suspense,
    lazy,
    useContext
} from 'react';
import type { Album, Artist, Currency, Track } from 'types';

const BuyNowMusicDialog = lazy(
    () =>
        import(
            /* webpackChunkName: "buy-now-music-dialog" */ 'components/dialogs/BuyNowMusicDialog'
        )
);

export type BuyNowMusicData = {
    price: string;
    title: string;
    artist: Artist;
    album: Omit<Album, 'artist'>;
    year?: string;
    tracks: Track[];
    type: 'Album' | 'Track';
    currency: Currency;
    id: number;
};

const BuyNowMusicContext = createContext<{
    buyNowMusicData: BuyNowMusicData | null;
    setBuyNowMusicData: Dispatch<SetStateAction<BuyNowMusicData | null>>;
}>({
    buyNowMusicData: null,
    setBuyNowMusicData: () => {
        throw new Error('setBuyNowMusicData is not defined');
    }
});

type Props = { children: ReactNode };

export const BuyNowMusicProvider = ({ children }: Props) => {
    const [buyNowMusicData, setBuyNowMusicData] =
        useState<BuyNowMusicData | null>(null);

    const value = useMemo(
        () => ({
            buyNowMusicData,
            setBuyNowMusicData
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [buyNowMusicData]
    );

    const onClose = useCallback(() => {
        setBuyNowMusicData(null);
    }, []);

    return (
        <BuyNowMusicContext.Provider value={value}>
            {children}
            {buyNowMusicData && (
                <Suspense fallback={null}>
                    <BuyNowMusicDialog
                        open
                        onClose={onClose}
                        album={buyNowMusicData.album}
                        tracks={buyNowMusicData.tracks}
                        artist={buyNowMusicData.artist}
                        currency={buyNowMusicData.currency}
                        id={buyNowMusicData.id}
                        price={buyNowMusicData.price}
                        title={buyNowMusicData.title}
                        type={buyNowMusicData.type}
                    />
                </Suspense>
            )}
        </BuyNowMusicContext.Provider>
    );
};

export const useBuyNowMusic = () => {
    const context = useContext(BuyNowMusicContext);

    return context;
};

BuyNowMusicContext.displayName = 'BuyNowMusicContext';

export default BuyNowMusicContext;
