import { lazy } from 'react';

export default lazy(
    () => import(/* webpackChunkName: "artist-sales" */ './Sales')
);

const SongManagement = lazy(
    () =>
        import(
            /* webpackChunkName: "artist-song-management" */ './SongManagement'
        )
);

const ContentUpload = lazy(
    () =>
        import(
            /* webpackChunkName: "artist-content-upload" */ './ContentUpload'
        )
);

export { SongManagement, ContentUpload };
