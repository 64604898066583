import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import type { Track, Artist, Album, TrackRights } from 'types';
import config from 'config';

type TrackArtists = Partial<Pick<Track, 'artist'>> & Pick<Track, 'artists'>;
export const getTrackArtists = (track: TrackArtists) => {
    const artists = (track.artist ? [track.artist] : [])
        .concat(track.artists ?? [])
        .map(artist => artist.stageName);

    const albumArtists = artists[0]?.split(/, | & /);
    const contributingArtists = artists.slice(1);

    if (artists.length > 1 && albumArtists.length > 1) {
        const intersection = albumArtists.filter(artist =>
            contributingArtists.includes(artist)
        );

        if (intersection.length) {
            return contributingArtists;
        }
    }

    return uniq(artists);
};

export const getTrackAlbumArtistsText = (track: TrackArtists) => {
    return getTrackArtists(track).join(', ');
};

export const findTrackIndex = (
    tracks: Track[] = [],
    trackId: number | undefined
) => {
    return tracks.findIndex((item: Track) => item.id === trackId);
};

export const getTrackFile = ({
    artist,
    album,
    file,
    rights
}: {
    artist: Artist;
    album?: Album;
    file: string;
    rights: TrackRights;
}) => {
    return rights !== 0
        ? `//${config.CONTENT_HOST}/media/${artist.file}/${album?.file}/${file}.mp3`
        : '';
};

export const getTrackImage = (
    {
        artist,
        album
    }: {
        artist: Artist;
        album: Album;
    },
    size = 'normal'
) => {
    return `//${config.STATIC_HOST}/album-art/${size}/${artist.slug}-${album.slug}.jpg`;
};

export const getTracksArtists = (tracks: Track[] = []) => {
    return uniqBy(
        tracks.reduce((artists, track) => {
            if (track.artists) {
                return artists.concat(track.artists);
            }

            return artists;
        }, [] as Artist[]),
        'id'
    );
};
