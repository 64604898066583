import { compile } from 'path-to-regexp';
import type {
    UseMutationOptions,
    UseQueryOptions
} from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosResponseWithData } from 'overrides/axios';
import axios from 'overrides/axios';
import type { ConditionGroup, Track, TrackId, TrackLike } from 'types';
import type { FetchResponseError } from 'types/fetch';
import URIS from './uris.json';
import { usersQueryKeys } from './users';

const getTrackCountUrl = compile<{ trackId: TrackId }>(`${URIS.tracks.count}`);
const getTrackLikeUrl = compile<{ trackId: TrackId }>(`${URIS.tracks.like}`);
const getTrackUrl = compile<{ trackId: TrackId }>(`${URIS.tracks.index}`);

export const tracksQueryKeys = {
    TRACK: 'track',
    TRACK_COUNT: 'track.count',
    TRACK_LIKE: 'track.like',
    TRACK_RECOMMENDATIONS: 'track.recommendations',
    TRACK_LYRICS: 'track.lyrics'
};

export const trackCount = async ({ trackId }: { trackId: TrackId }) => {
    const response = await axios.put<{}, AxiosResponseWithData<Track>>(
        getTrackCountUrl({
            trackId
        })
    );

    return response.data;
};

type TrackLikeRequest = { status?: boolean };
export const useTrackLikeMutation = (
    trackId: TrackId,
    options?: UseMutationOptions<
        TrackLike,
        FetchResponseError,
        TrackLikeRequest
    >
) => {
    const queryClient = useQueryClient();
    const url = getTrackLikeUrl({
        trackId
    });

    return useMutation({
        ...options,
        mutationFn: async ({ status }: TrackLikeRequest) => {
            const response = await axios.request<TrackLike>({
                url,
                method: status === undefined ? 'delete' : 'put',
                data: {
                    status
                }
            });

            return response.data;
        },
        onMutate: async data => {
            await queryClient.cancelQueries([
                tracksQueryKeys.TRACK_LIKE,
                { trackId }
            ]);

            const currentTrackLike = queryClient.getQueryData<TrackLike>([
                tracksQueryKeys.TRACK_LIKE,
                { trackId }
            ]);

            queryClient.setQueryData<TrackLike>(
                [tracksQueryKeys.TRACK_LIKE, { trackId }],
                data
            );

            return {
                data: currentTrackLike
            };
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries([
                tracksQueryKeys.TRACK_LIKE,
                { trackId }
            ]);

            queryClient.invalidateQueries([usersQueryKeys.USER_PLAYLISTS]);
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(
                [tracksQueryKeys.TRACK_LIKE, { trackId }],
                context?.data
            );
        }
    });
};

export const useTrackLikeQuery = (
    trackId: TrackId,
    { enabled, ...options }: UseQueryOptions<TrackLike, FetchResponseError>
) => {
    return useQuery<TrackLike, FetchResponseError>({
        queryKey: [tracksQueryKeys.TRACK_LIKE, { trackId }],
        queryFn: async () => {
            const response = await axios.get<TrackLike>(
                getTrackLikeUrl({
                    trackId
                })
            );

            return response.data;
        },

        ...options,
        enabled: Boolean(trackId) && enabled !== false,
        staleTime: Infinity
    });
};

export const useMusicMixMutation = (
    options?: UseMutationOptions<Track[], FetchResponseError, ConditionGroup>
) => {
    return useMutation({
        ...options,
        mutationFn: async data => {
            const response = await axios.post<Track[]>(
                URIS.tracks.musicMix,
                data,
                {
                    timeout: 200 * 1000
                }
            );

            return response.data;
        }
    });
};

export const useSongQuery = (trackId: TrackId) => {
    const fields = [
        'album.genre',
        'album.price',
        'artist',
        'artist.albums.price',
        'artists'
    ].join();

    const url = getTrackUrl({
        trackId
    });

    return useQuery<Track, FetchResponseError>({
        queryKey: [tracksQueryKeys.TRACK, { trackId }],
        queryFn: async () => {
            const response = await axios.get<Track>(url, {
                params: {
                    fields
                }
            });

            return response.data;
        },
        enabled: trackId !== (-1 as TrackId)
    });
};

export type RecommendationsRequest = {
    trackIds: TrackId[] | undefined;
};

export const getRecommendedTracks = async (data: RecommendationsRequest) => {
    const response = await axios.get<Track[]>(URIS.tracks.recommendations, {
        params: {
            trackIds: data.trackIds
        }
    });

    return response.data;
};
