import type { MouseEvent, ChangeEvent } from 'react';
import { useCallback, lazy, Suspense, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import classnames from 'classnames';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { useDispatch, useSelector } from 'react-redux';
import AccountInfo from 'components/AccountInfo';
import ROUTES from 'shared/routes';
import type { TabOptions } from 'types';
import CastButton from 'components/inputs/CastButton';
import { useLayout } from 'hooks/use-layout';
import { getMusicCart } from 'selectors';
import type { AppDispatch } from 'store';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import SearchInput from '../search/Input';
import Logo from '../Logo';
import Drawer from '../Drawer';
import { tabChange } from '../../actions';
import styles from './styles';

const Tabs = lazy(() => import(/* webpackChunkName: "tabs" */ '../Tabs'));

const useStyles = makeStyles(styles);

type Props = {
    classPrefix?: string;
    hideTitle?: boolean;
    isPageReady: boolean;
    isSearchOpen: boolean;
    onBackButtonClick: (e: MouseEvent<HTMLButtonElement>) => void;
    showBackButton?: boolean;
    tabIndex: number;
    tabs: TabOptions[];
    title: string;
    onSearch: (keyword: string) => void;
    onSearchClose: () => void;
    onSearchOpen: () => void;
};

const NavigationBar = ({
    classPrefix = 'navigation',
    hideTitle = false,
    tabIndex,
    tabs,
    isSearchOpen,
    isPageReady,
    onBackButtonClick,
    showBackButton,
    title,
    onSearch,
    onSearchClose,
    onSearchOpen
}: Props) => {
    const { isDesktop, isMobile } = useLayout();
    const [isOpen, setIsOpen] = useState(false);
    const isLoggedIn = useIsLoggedIn();
    const classes = useStyles();
    const theme = useTheme();
    const showTab = Boolean(tabs.length && !isSearchOpen);
    const className = classnames({
        [`${classPrefix}-wrapper`]: true,
        [`${classPrefix}-tab`]: showTab,
        flex: true
    });
    const musicCart = useSelector(getMusicCart);
    const dispatch = useDispatch<AppDispatch>();

    const onDrawerOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    const onDrawerClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const renderTabs = () => {
        return (
            <Tabs
                onChange={(e: ChangeEvent<{}>, index: number) => {
                    e.preventDefault();
                    dispatch(tabChange(index));
                }}
                tabIndex={tabIndex}
                tabs={tabs}
            />
        );
    };

    const renderLoginOrAccountInfo = () => {
        return <AccountInfo showInfo={false} isMenu />;
    };

    const renderCartButton = () => {
        return (
            <IconButton
                disabled={!musicCart.quantity}
                size='large'
                style={{
                    width: 48,
                    height: 48
                }}
            >
                <Link to={ROUTES.CART}>
                    <Badge badgeContent={musicCart.quantity} color='primary'>
                        <ShoppingCartIcon
                            color={!musicCart.quantity ? 'disabled' : 'action'}
                        />
                    </Badge>
                </Link>
            </IconButton>
        );
    };

    return (
        <nav
            role='navigation'
            className={`${className} ${classes.root}`}
            data-test={title}
        >
            <AppBar
                className={
                    !isSearchOpen ? classes.appBar : classes.appBarSearch
                }
                enableColorOnDark
            >
                <Toolbar className={classes.toolbar} disableGutters>
                    {isDesktop && (
                        <div
                            style={{
                                marginLeft: 16
                            }}
                        >
                            <Logo color='green' />
                        </div>
                    )}
                    {!isSearchOpen && (
                        <div className='flex-fixed'>
                            {!isDesktop && (
                                <IconButton
                                    id='drawer-menu'
                                    aria-label='open drawer'
                                    onClick={
                                        showBackButton
                                            ? onBackButtonClick
                                            : onDrawerOpen
                                    }
                                    className={classes.muiIconButtonLeft}
                                    data-test={
                                        showBackButton
                                            ? 'arrow-back'
                                            : 'drawer-menu'
                                    }
                                    size='large'
                                >
                                    {showBackButton ? (
                                        <ArrowBack />
                                    ) : (
                                        <MenuIcon />
                                    )}
                                </IconButton>
                            )}
                        </div>
                    )}
                    {(!isSearchOpen || isDesktop) && (
                        <div className='flex flex-direction-row flex-grow flex-justify-space-between'>
                            {!isDesktop && (
                                <Typography variant='h6' noWrap>
                                    {!hideTitle && title}
                                </Typography>
                            )}
                        </div>
                    )}
                    {isMobile && !isSearchOpen && !showBackButton && (
                        <CastButton
                            disconnectedColor={theme.palette.action.active}
                        />
                    )}
                    <SearchInput
                        isSearchOpen={isSearchOpen}
                        showBackButton={showBackButton}
                        onSearch={onSearch}
                        onClose={onSearchClose}
                        onOpen={onSearchOpen}
                    />

                    {(isDesktop ||
                        (isMobile && !isSearchOpen && !showBackButton)) &&
                        renderCartButton()}
                    <div className='login-or-account-info'>
                        {isDesktop && renderLoginOrAccountInfo()}
                    </div>
                </Toolbar>
                {!isDesktop && showTab && (
                    <Suspense fallback={<LinearProgress />}>
                        {renderTabs()}
                    </Suspense>
                )}
            </AppBar>
            {(isPageReady || isDesktop) && (
                <Drawer
                    isLoggedIn={isLoggedIn}
                    isOpen={isOpen}
                    onClick={onDrawerClose}
                    onClose={onDrawerClose}
                    onOpen={onDrawerOpen}
                />
            )}
            {isDesktop && showTab && (
                <Suspense fallback={<LinearProgress />}>
                    {renderTabs()}
                </Suspense>
            )}
        </nav>
    );
};

export default NavigationBar;
