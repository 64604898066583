import {
    FETCH_USERS_SUCCESS,
    FETCH_LOGOUT_SUCCESS,
    FETCH_LOGOUT
} from 'actions/types';
import type { User } from 'types';
import type Actions from 'actions';

const initialState = {
    playlists: []
} as unknown as User;

const user = (state = initialState, action: Actions): User => {
    switch (action.type) {
        case FETCH_USERS_SUCCESS: {
            const {
                data,
                meta: {
                    // @ts-ignore
                    originalAction: {
                        payload: { method }
                    }
                }
            } = action.payload;

            return ['get', 'patch'].includes(method)
                ? {
                      ...state,
                      ...{
                          ...data
                      }
                  }
                : state;
        }
        case FETCH_LOGOUT:
        case FETCH_LOGOUT_SUCCESS: {
            return initialState;
        }

        default:
            return state;
    }
};

export default user;
