import type { User } from 'types';

export const isVerified = (user: User) => {
    let verified = true;

    if (user.type === 'artist') {
        verified = Boolean(user.artist);
    }

    return verified;
};
