import config from 'config';
import type { AlbumSlug, ArtistSlug, User } from 'types';
import { getUrl, gravatarUrl } from './url';

const uri = `//${config.STATIC_HOST}/album-art/:size/:artistSlug\\-:albumSlug.jpg`;

type Options = {
    artistSlug: ArtistSlug;
    albumSlug: AlbumSlug;
};

export const getAlbumImage = (
    { artistSlug, albumSlug }: Options,
    size = 'normal'
) =>
    getUrl(uri, {
        artistSlug,
        albumSlug,
        size
    });

export const getUserImage = (user: User, size = 40) => {
    if (user.image) {
        return `//${config.CDN_PREFIX}/c_crop,g_custom/${user.image}`;
    }

    const userPicture = getUserPicture(user);

    if (userPicture) {
        return userPicture;
    }

    return gravatarUrl(user.email, {
        size
    });
};

export const getUserPicture = (user: User) => {
    const pictures = getUserPictures(user);

    if (pictures) {
        return pictures[0];
    }

    return null;
};

export const getUserPictures = (user: User) =>
    user.identities?.map(identity => identity.picture);
