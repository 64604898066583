import type { MenuOption, Track, Album, Currency } from 'types';
import { ON_ADD_TO_CART, ON_MENU_CLICK, SHARE } from '../types/app';

export const onMenuClick = <T>(option: MenuOption, value: T) =>
    ({
        type: ON_MENU_CLICK,
        payload: {
            option,
            value
        }
    } as const);

export const onShare = ({
    title,
    url,
    text
}: {
    title: string;
    url?: string;
    text?: string;
}) =>
    ({
        type: SHARE,
        payload: {
            title,
            url,
            text
        }
    } as const);

export const onAddToCart = ({
    type,
    data,
    currency
}: {
    type: 'albums' | 'tracks';
    data: Album | Track;
    currency: Currency;
}) =>
    ({
        type: ON_ADD_TO_CART,
        payload: {
            type,
            data,
            currency
        }
    } as const);
