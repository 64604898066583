import type { SimplePaletteColorOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import type { ColorSchemeOption, CSSTheme, ThemeType } from 'types';
import { toThemeType } from 'helpers/to-theme-type';

const themeDark: CSSTheme = {
    name: 'dark',
    variables: {
        '--background-color': '#121212',
        '--text-color': '#FFF',
        '--secondary': '#000',
        '--primary': '#FFF',
        '--grey': '#9e9e9e',
        '--dark-grey': '#f5f5f5'
    }
};

const themeLight: CSSTheme = {
    name: 'light',
    variables: {
        '--background-color': '#FFF',
        '--text-color': '#000',
        '--secondary': '#FFF',
        '--primary': '#0d904f',
        '--grey': '#212121',
        '--dark-grey': '#424242'
    }
};

const primaryColor: SimplePaletteColorOptions = {
    light: '#51c17c',
    main: '#0d904f',
    dark: '#006125'
};

const secondaryColor: SimplePaletteColorOptions = {
    light: '#5ff398',
    main: '#11BF69',
    dark: '#008d3d'
};

const theme = (option: ColorSchemeOption) => {
    const colorScheme = toThemeType(option);

    const color = colorScheme === 'light' ? 'black' : 'white';

    setCSSTheme(colorScheme);

    return createTheme({
        components: {
            MuiTabs: {
                styleOverrides: {
                    root: {
                        color
                    },
                    indicator: {
                        backgroundColor: primaryColor.main
                    }
                }
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        padding: '0 16px',
                        marginTop: '3px',
                        marginBottom: '3px'
                    }
                }
            },
            MuiTypography: {
                defaultProps: {
                    variant: 'body2'
                },
                styleOverrides: {
                    root: {
                        color
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: 'inherit',
                        marginRight: '16px'
                    }
                }
            },
            MuiLink: {
                defaultProps: {
                    underline: 'hover'
                }
            }
        },
        palette: {
            mode: colorScheme,
            primary: primaryColor,
            secondary: secondaryColor
        }
    });
};

const setCSSTheme = (colorScheme: ThemeType) => {
    const cssTheme = colorScheme === 'light' ? themeLight : themeDark;

    const rules = [];
    const themeId = 'theme';
    let style = document.getElementById(themeId) as HTMLStyleElement;

    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (style && style.sheet?.cssRules) {
        while (style.sheet.cssRules.length > 0) {
            style.sheet.deleteRule(style.sheet.cssRules.length - 1);
        }
    }

    if (!style) {
        style = document.createElement('style');
        style.setAttribute('id', themeId);
        document.head.appendChild(style);
    }

    for (const [key, value] of Object.entries(cssTheme.variables)) {
        rules.push(`${key}: ${value};`);
    }

    style?.sheet?.insertRule?.(
        `:root { ${rules.join('')} }`,
        style.sheet.cssRules.length
    );
};

export default theme;
