import { lazy } from 'react';

export const Playlist = lazy(
    () => import(/* webpackChunkName: "playlist" */ './Playlist')
);

export const UserOrDjPlaylist = lazy(
    () =>
        import(
            /* webpackChunkName: "user-or-dj-playlist" */ './UserOrDjPlaylist'
        )
);
