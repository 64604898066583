export const LOAD = '@@player/LOAD';
export const ON_LOAD = 'ON_LOAD';
export const ON_BUFFER_CHANGE = 'ON_BUFFER_CHANGE';
export const ON_ADD_TO_QUEUE = '@@player/ON_ADD_TO_QUEUE';
export const ON_REMOVE_FROM_QUEUE = '@@player/ON_REMOVE_FROM_QUEUE';
export const ON_CAST_CONNECT = 'ON_CAST_CONNECT';
export const ON_CAST_DISCONNECT = 'ON_CAST_DISCONNECT';
export const ON_CAST_STATE_CHANGE = 'ON_CAST_STATE_CHANGE';
export const ON_CAST_DEVICE_NAME_CHANGE = 'ON_CAST_DEVICE_NAME_CHANGE';
export const ON_ERROR = 'ON_ERROR';
export const ON_NEXT = '@@player/ON_NEXT';
export const ON_PAUSE = '@@player/ON_PAUSE';
export const ON_PAUSED = 'ON_PAUSED';
export const ON_PLAY = '@@player/ON_PLAY';
export const ON_PLAYER_READY = 'ON_PLAYER_READY';
export const ON_PLAYING = 'ON_PLAYING';
export const ON_PLAYLIST = 'ON_PLAYLIST';
export const ON_PLAYLIST_ITEM = 'ON_PLAYLIST_ITEM';
export const ON_PREV = '@@player/ON_PREV';
export const ON_REPEAT = '@@player/ON_REPEAT';
export const ON_SHUFFLE = '@@player/ON_SHUFFLE';
export const ON_STOP = 'ON_STOP';
