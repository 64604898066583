import { useLocation } from 'react-router-dom';
import Empty from 'components/Empty';

const PageNotFound = () => {
    const location = useLocation();

    return (
        <Empty
            heading='Path not found'
            body='use the search to find what you are looking for'
        >
            {`path: ${location.pathname}${location.search}`}
        </Empty>
    );
};

export default PageNotFound;
