import memoize from 'lodash/memoize';
import { matchPath } from 'react-router-dom';
import ROUTES from 'shared/routes';
import type {
    AlbumParams,
    ShowParams,
    DjSlug,
    DjBookingId,
    TrackId,
    PlaylistId,
    ArtistSlug,
    GenreSlug
} from 'types';
import config from 'config';
import type { RouteValues } from 'shared/routes';
import { getUrl } from './url';
const uri = `//${config.STATIC_HOST}/album-art/:size/:image.jpg`;

export const getAlbumUri = ({ artistSlug, albumSlug }: AlbumParams) =>
    getUrl(ROUTES.ALBUM, {
        artistSlug,
        albumSlug
    });

export const getShowTicketsUri = ({ showId }: ShowParams) =>
    getUrl(ROUTES.ORGANIZER_SHOW_TICKETS, {
        showId
    });

export const getShowPayoutUri = ({ showId }: ShowParams) =>
    getUrl(ROUTES.ORGANIZER_SHOW_PAYOUT, {
        showId
    });

type Options = {
    image: string;
};
export const getAlbumImageUrl = ({ image }: Options, size = 'normal') =>
    getUrl(uri, {
        image,
        size
    });

export const getShowUri = ({ showId }: ShowParams) =>
    getUrl(ROUTES.SHOW, {
        showId
    });

export const getUserDjBookingUri = (bookingId: DjBookingId) =>
    getUrl(ROUTES.USER_BOOKING, { bookingId });

export const getSongUri = (trackId: TrackId) => {
    return getUrl(ROUTES.SONG, {
        trackId
    });
};

export const getShowPaymentUri = ({ showId }: ShowParams) =>
    getUrl(ROUTES.SHOW_PAYMENT, {
        showId
    });

export const getPlaylistUri = (playlistId: PlaylistId) => {
    return `${globalThis.location.origin}${getUrl(ROUTES.PLAYLISTS, {
        playlistId
    })}`;
};

export const getBookingPayoutUri = (bookingId: DjBookingId) =>
    getUrl(ROUTES.DJ_BOOKING_PAYOUT, {
        bookingId
    });

export const getArtistUri = (artistSlug: ArtistSlug) =>
    getUrl(ROUTES.ARTIST, {
        artistSlug
    });

export const getGenreUri = (genreSlug: GenreSlug) =>
    getUrl(ROUTES.GENRE, {
        genreSlug
    });

export const getShowModifyUri = ({ showId }: ShowParams) =>
    getUrl(ROUTES.ORGANIZER_SHOW_MODIFY, {
        showId
    });

export const getDjBookingUri = (djSlug: DjSlug, bookingId: DjBookingId) =>
    getUrl(ROUTES.DJ_BOOKING, {
        djSlug,
        bookingId
    });

export const getRequestDjBookingUri = (djSlug: DjSlug) =>
    getUrl(ROUTES.REQUEST_DJ_BOOKING, {
        djSlug
    });

export const getDjUri = (djSlug: DjSlug) =>
    getUrl(ROUTES.DJ, {
        djSlug
    });

export const getShowPreviewUri = ({ showId }: ShowParams) =>
    getUrl(ROUTES.ORGANIZER_SHOW_PREVIEW, {
        showId
    });

export const getShowCouponsUri = ({ showId }: ShowParams) =>
    getUrl(ROUTES.ORGANIZER_SHOW_COUPONS, {
        showId
    });

export const getUriRoute = memoize((pathname: string): RouteValues => {
    const [, route = pathname] =
        Object.entries(ROUTES).find(([, route]) => {
            return Boolean(getUriMatch(pathname, route));
        }) || [];

    return route as RouteValues;
});

export const getUriMatch = (pathname: string, pattern: string) =>
    matchPath(pattern, pathname);
