import type { Track, Currency } from 'types';
import type { RootState } from 'store';

// player selectors
export const getPlayer = (state: RootState) => state.player;
export const getPlayerStatus = (state: RootState) => getPlayer(state).status;
export const getIsPlaying = (state: RootState) =>
    getPlayerStatus(state) === 'playing';
export const getPlayerBufferPercent = (state: RootState) =>
    getPlayer(state).bufferPercent;
export const getPlayerPlaylist = (state: RootState) =>
    getPlayer(state).playlist;
export const getPlayerTrack = (state: RootState) => {
    const items = getPlayerPlaylist(state).items;

    if (items.length) {
        const player = getPlayer(state);

        return items[player.currentItemIndex];
    }
};
export const getIsCurrentTrack = (state: RootState, track: Track) => {
    const currentTrack = getPlayerTrack(state);

    if (currentTrack) {
        return currentTrack.id === track.id;
    }

    return false;
};

// shell selectors
export const getShell = (state: RootState) => state.shell;

export const getIsLoggedIn = (state: RootState) => {
    return Boolean(getUser(state).id);
};

// user selectors
export const getUser = (state: RootState) => state.user;

// show selector
export const getCart = (state: RootState, showId: string) => {
    const cart = state.cart;

    if (showId !== cart.id) {
        return {
            id: '',
            tickets: {},
            totalAmount: 0,
            currency: 'NGN' as Currency
        };
    }

    return cart;
};
export const getMusicCart = (state: RootState) => {
    return state.musicCart;
};
