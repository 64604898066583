/// <reference types="@welldone-software/why-did-you-render" />
import React from 'react';
import logger from 'utilities/logger';
import { isDevEnv } from 'helpers/env';

if (isDevEnv()) {
    logger.debug('why-did-you-render is enabled');

    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    const ReactRedux = require('react-redux/dist/react-redux.js');

    whyDidYouRender(React, {
        trackAllPureComponents: true,
        trackHooks: true,
        trackExtraHooks: [[ReactRedux, 'useSelector']],
        logOwnerReasons: true
    });
}
