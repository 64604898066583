import { snackbarOpen, onConnection } from 'components/shell/actions';
import { getAppName } from 'helpers/app';
import RefreshButton from 'components/inputs/RefreshButton';
import type { Connection } from 'types/store';

export const onAppUpdate = (registration: ServiceWorkerRegistration) => {
    const action = (
        <RefreshButton
            onClick={() => {
                registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
            }}
        />
    );

    return snackbarOpen(`A new version of ${getAppName()} is available`, {
        action,
        persist: true
    });
};

export const onConnectionChange = (type: Connection) => {
    return onConnection(type);
};
