import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import type { AppDispatch } from 'store';
import { notificationClose } from 'actions';
import ROUTES from 'shared/routes';

const PrivacyLink = () => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <div
            style={{
                padding: '10px'
            }}
        >
            <Typography fontSize={10}>
                Please refer to our{' '}
                <Link
                    onClick={e => dispatch(notificationClose())}
                    to={ROUTES.ABOUT}
                >
                    privacy policy
                </Link>{' '}
                to see how we use your data.
            </Typography>
        </div>
    );
};

export default PrivacyLink;
