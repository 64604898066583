import { compile } from 'path-to-regexp';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponseWithData } from 'overrides/axios';
import axios from 'overrides/axios';
import type { Playlist, PlaylistId } from 'types';
import type { FetchResponseError } from 'types/fetch';
import URIS from './uris.json';

const getPlaylistUrl = compile(`${URIS.playlists.index}`);
const getPlaylistCountUrl = compile<{ playlistId: PlaylistId }>(
    `${URIS.playlists.count}`
);

export const playlistQueryKeys = {
    PLAYLIST: 'playlist',
    PLAYLIST_COUNT: 'playlist.count'
};

export const usePlaylistQuery = <Playlist>(
    playlistId: PlaylistId,
    options?: UseQueryOptions<Playlist, FetchResponseError>
) => {
    return useQuery<Playlist, FetchResponseError>({
        queryKey: [playlistQueryKeys.PLAYLIST, { playlistId }],
        queryFn: async () => {
            const response = await axios.get<Playlist>(
                getPlaylistUrl({
                    playlistId
                }),
                {
                    params: {
                        fields: [
                            'tracks.album.price',
                            'tracks.artist',
                            'tracks.artists'
                        ].join()
                    }
                }
            );

            return response.data;
        },
        ...options
    });
};

export const playlistCount = async ({
    playlistId
}: {
    playlistId: PlaylistId;
}) => {
    const response = await axios.put<{}, AxiosResponseWithData<Playlist>>(
        getPlaylistCountUrl({
            playlistId
        })
    );

    return response.data;
};
