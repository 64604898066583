import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 1,
            cacheTime: 1000 * 60 * 15,
            networkMode: 'offlineFirst'
        },
        mutations: {
            networkMode: 'offlineFirst'
        }
    }
});

export default queryClient;
