import logger from 'js-logger';
import { isTestEnv, isProdEnv } from 'helpers/env';

// Log messages will be written to the window's console.
logger.useDefaults();
logger.setHandler(function logHandler(messages, context) {
    // Send messages to a custom logging endpoint for analysis.
    if (isProdEnv()) {
        return;
    }
});

export const axiosLogger = logger.get('axios');

if (isTestEnv()) {
    logger.setLevel(logger.OFF);
} else if (isProdEnv()) {
    axiosLogger.setLevel(logger.OFF);
}

export default logger;
