import { memo } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ROUTES from 'shared/routes';
import { getAppName } from 'helpers/app';

type Props = {
    className?: string;
    color?: 'green' | 'white';
};

const logoPath = '/static/images/logo';
const logoFile = 'epoh-music.svg';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            backgroundImage: `url(${logoPath}/white/${logoFile})`,
            '&.green': {
                backgroundImage: `url(${logoPath}/green/${logoFile})`
            }
        }
    })
);

const Logo = ({ className, color = 'white' }: Props) => {
    const classNames = classnames('brand-logo', {
        [className as string]: !!className
    });
    const classes = useStyles();

    return (
        <Link
            title={`${getAppName()}`}
            to={ROUTES.HOME}
            className={`${color} ${classNames} ${classes.root}`}
        />
    );
};

export default memo(Logo);
