import type { ReactNode } from 'react';
import { forwardRef, useMemo } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';

type Props = Omit<
    ButtonProps &
        RouterLinkProps & {
            children?: ReactNode;
            to: string;
        },
    'component'
>;

const ButtonLink = ({ children, to, ...props }: Props) => {
    const Link = useMemo(() => {
        // eslint-disable-next-line react/display-name
        return forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
            <RouterLink ref={ref} to={to} {...props} role={undefined} />
        ));
    }, [to]);

    return (
        <Button component={Link} {...props}>
            {children}
        </Button>
    );
};

export default ButtonLink;
