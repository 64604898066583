import { memo, useState } from 'react';
import type { MouseEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ROUTES from 'shared/routes';
import { fetchLogout } from 'actions';
import { useUser } from 'hooks/use-user';
import { getUserImage } from 'helpers/image';
import type { AppDispatch } from 'store';

type Props = {
    showInfo?: boolean;
    imageSize?: number;
    isMenu?: boolean;
};

const AccountInfo = ({
    imageSize = 40,
    showInfo = true,
    isMenu = false
}: Props) => {
    const user = useUser();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const isLoggedIn = Boolean(user?.id);

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const onLogoutClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(fetchLogout());
        navigate(ROUTES.HOME, { replace: true });
    };

    const onFaqClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        navigate(ROUTES.FAQ);
    };

    const onAboutClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        navigate(ROUTES.ABOUT);
    };

    const onSettingsClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        navigate(ROUTES.SETTINGS);
    };

    const onProfileClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        navigate(ROUTES.SETTINGS_PROFILE);
    };

    const onLoginClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        navigate(ROUTES.LOGIN);
    };

    const onRegisterClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        navigate(ROUTES.REGISTER);
    };

    return (
        <div className='account-info flex' data-test-id='account-info'>
            <div className='flex flex-direction-row'>
                <Tooltip
                    title={
                        isLoggedIn ? 'Account settings' : 'Sign In or Register'
                    }
                >
                    <IconButton
                        onClick={isMenu ? onClick : undefined}
                        size='small'
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar
                            alt='user avatar'
                            style={{
                                height: `${imageSize}px`,
                                width: `${imageSize}px`
                            }}
                            src={isLoggedIn ? getUserImage(user) : undefined}
                        />
                    </IconButton>
                </Tooltip>
                {isMenu && (
                    <Menu
                        anchorEl={anchorEl}
                        id='account-menu'
                        open={open}
                        onClose={onClose}
                        onClick={onClose}
                        slotProps={{
                            paper: {
                                elevation: 1,
                                sx: {
                                    minWidth: 125,
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        backgroundColor: 'background.paper',
                                        transform:
                                            'translateY(-50%) rotate(45deg)',
                                        zIndex: 0
                                    }
                                }
                            }
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top'
                        }}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom'
                        }}
                    >
                        {isLoggedIn
                            ? [
                                  <MenuItem
                                      key='profile'
                                      dense
                                      onClick={onProfileClick}
                                  >
                                      <ListItemIcon sx={{ mr: 0 }}>
                                          <PersonIcon fontSize='small' />
                                      </ListItemIcon>
                                      Profile
                                  </MenuItem>,
                                  <MenuItem
                                      key='settings'
                                      onClick={onSettingsClick}
                                  >
                                      <ListItemIcon sx={{ mr: 0 }}>
                                          <SettingsIcon fontSize='small' />
                                      </ListItemIcon>
                                      Settings
                                  </MenuItem>,
                                  <MenuItem
                                      key='logout'
                                      dense
                                      onClick={onLogoutClick}
                                  >
                                      <ListItemIcon sx={{ mr: 0 }}>
                                          <LogoutIcon fontSize='small' />
                                      </ListItemIcon>
                                      Logout
                                  </MenuItem>
                              ]
                            : [
                                  <MenuItem
                                      key='sign-in'
                                      onClick={onLoginClick}
                                  >
                                      <ListItemIcon sx={{ mr: 0 }}>
                                          <LoginIcon fontSize='small' />
                                      </ListItemIcon>
                                      Sign In
                                  </MenuItem>,
                                  <MenuItem
                                      key='register'
                                      dense
                                      onClick={onRegisterClick}
                                  >
                                      <ListItemIcon sx={{ mr: 0 }}>
                                          <PersonAddIcon fontSize='small' />
                                      </ListItemIcon>
                                      Register
                                  </MenuItem>
                              ]}
                        <Divider />
                        <MenuItem onClick={onFaqClick}>FAQ</MenuItem>
                        <MenuItem onClick={onAboutClick}>About</MenuItem>
                    </Menu>
                )}
                {showInfo && !isMenu && isLoggedIn && (
                    <div
                        className='flex'
                        style={{
                            minWidth: 0
                        }}
                    >
                        <Typography variant='body1'>
                            {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        <div>
                            <Typography className='ellipsis' title={user.email}>
                                {user.email}
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(AccountInfo);
