import { playlistCount } from 'queries/playlists';
import { trackCount } from 'queries/tracks';
import type { PlaylistId, TrackId, Track } from 'types';
import { axiosLogger } from 'utilities/logger';

const audio = new Audio();

let isTrackCounted = false;
let trackCountTimerId: NodeJS.Timeout | number | undefined = undefined;
let isPlaylistCounted = false;
let playlistCountTimerId: NodeJS.Timeout | number | undefined = undefined;
let isGenerated = false;

const TRACK_COUNT_THRESHOLD_IN_SECS = 5;
const PLAYLIST_COUNT_THRESHOLD_IN_SECS = 60;

const onPlaying = () => {
    const trackId = audio.dataset.trackId! as unknown as TrackId;
    const paramType = audio.dataset.paramType!;
    const paramId = audio.dataset.paramId!;

    isGenerated = audio.dataset.generated === 'true';

    if (paramType === 'playlist' && paramId) {
        if (!isPlaylistCounted && !playlistCountTimerId && !isGenerated) {
            clearTimeout(playlistCountTimerId);

            playlistCountTimerId = setTimeout(() => {
                if (paramId !== audio.dataset.paramId) {
                    playlistCountTimerId = undefined;

                    return;
                }

                playlistCount({
                    playlistId: paramId as PlaylistId
                })
                    .then(() => {
                        isPlaylistCounted = true;
                        playlistCountTimerId = undefined;
                    })
                    .catch(e => {
                        axiosLogger.error(e);
                    });
            }, PLAYLIST_COUNT_THRESHOLD_IN_SECS * 1000);
        }
    }

    if (trackId) {
        if (!isTrackCounted && !trackCountTimerId) {
            clearTimeout(trackCountTimerId);

            trackCountTimerId = setTimeout(() => {
                if (trackId !== (audio.dataset.trackId as unknown as TrackId)) {
                    trackCountTimerId = undefined;

                    return;
                }

                trackCount({ trackId })
                    .then(() => {
                        isTrackCounted = true;
                        trackCountTimerId = undefined;
                    })
                    .catch(e => {
                        axiosLogger.error(e);
                    });
            }, TRACK_COUNT_THRESHOLD_IN_SECS * 1000);
        }
    }
};

export const resetTrackCount = () => {
    isTrackCounted = false;
};

export const resetPlaylistCount = () => {
    isPlaylistCounted = false;
};

export const seek = (seekTime: number) => {
    audio.currentTime = seekTime;
};

export const getCurrentTime = () => {
    return typeof audio.currentTime === 'number' ? audio.currentTime : 0;
};

export const getPlaybackRate = () => {
    return audio.playbackRate;
};

export const onTimeUpdate = (callback: () => void) => {
    audio.addEventListener('timeupdate', callback);

    return () => {
        audio.removeEventListener('timeupdate', callback);
    };
};

export const onEnded = (callback: () => void) => {
    audio.addEventListener('ended', callback);

    return () => {
        audio.removeEventListener('ended', callback);
    };
};

export const stop = () => {
    audio.pause();
    audio.currentTime = 0;
};

export const setVolume = (volume: number) => {
    audio.volume = volume * 0.01;
};

export const getTags = (track: Track) => {
    return {
        title: track.title,
        album: track.album?.title,
        artist: track.artist.stageName,
        filename: audio.currentSrc
    };
};

audio.addEventListener('playing', onPlaying);
audio.addEventListener('loadstart', () => {
    if (audio.dataset.paramType === 'playlist') {
        resetPlaylistCount();
    }

    resetTrackCount();
});

export default audio;
