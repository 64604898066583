import {
    deleteFirebaseToken,
    getFirebaseToken
} from 'overrides/firebase.messaging';
import { deletePushToken, savePushToken } from 'queries/global';
import type { Topics } from 'types';

export const subscribeToPushNotification = async () => {
    const token = await getFirebaseToken();

    return savePushToken(token);
};

export const unsubscribeFromPushNotification = async () => {
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;

    const subscription =
        await serviceWorkerRegistration.pushManager.getSubscription();

    if (subscription) {
        await deleteFirebaseToken();
        await deletePushToken();

        await subscription.unsubscribe();
    }
};

export const isPushNotificationSupported = () => {
    if (!('Notification' in window)) {
        return false;
    }

    if (!('serviceWorker' in navigator)) {
        // Service Worker isn't supported on this browser, disable or hide UI.
        return false;
    }

    if (!('PushManager' in window)) {
        // Push isn't supported on this browser, disable or hide UI.
        return false;
    }

    return true;
};

export const isPushNotificationActionSupported = () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    if ('actions' in window.Notification?.prototype) {
        return true;
    }

    return false;
};

export const isPushNotificationPermissionGranted = () => {
    if (!isPushNotificationSupported()) {
        return false;
    }

    return Notification.permission === 'granted';
};

export const isPushNotificationPermissionDenied = () => {
    if (!isPushNotificationSupported()) {
        return false;
    }

    return Notification.permission === 'denied';
};

export const getPushTopicLabel = (topic: Topics) => {
    switch (topic) {
        case 'new-music':
            return 'New Music';
        case 'new-features':
            return 'New Features';
        case 'news-and-tips':
            return 'Tips & News';
    }
};
