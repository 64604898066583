import Button from '@mui/material/Button';

type Props = {
    variant?: 'contained' | 'outlined' | 'text';
    onClick?: Function;
};

const RefreshButton = ({ variant = 'text', onClick }: Props) => (
    <Button
        color='primary'
        size='small'
        onClick={() => {
            if (typeof onClick === 'function') {
                onClick();
            } else {
                window.location.reload();
            }
        }}
        variant={variant}
    >
        Refresh
    </Button>
);

export default RefreshButton;
